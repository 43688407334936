import {
  Box,
  Button,
  Card,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { DetailInfo, Bitacora, DetailAlign, DetailDataType } from "@paul-igas/igas-react-components";
import { useDispatch, useSelector } from "react-redux";
import { getLogs } from "../../../../store/consultas/bitacoraReplicacion";
import { useState } from "react";

export const DetailFechaHoraReplicacion = ({ open, onClose, data, encabezado, color, componente }) => {
  const [openBitacora, setopenBitacora] = useState(true);
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setopenBitacora(false);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={"sm"}
        scroll={"paper"}
        style={{ maxHeight: "600px" }}
      >
        <DialogTitle onClose={onClose}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              Detalle de replicación
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box bgcolor='default' border={2} borderRadius={5} borderColor={color} textAlign={"center"}>
                <Typography color={color}>{encabezado}</Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardHeader subheader='Última replicación' />
                <CardMedia sx={{ pl: 2 }}>
                  <DetailInfo
                    title='Fecha hora'
                    description={data.fechaHoraReplicacion}
                    dataType={DetailDataType.DateTime}
                    dataAlign={DetailAlign.Center}
                  ></DetailInfo>
                </CardMedia>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Card>
                <CardHeader subheader='Tiempo transcurrido' />

                <CardMedia sx={{ pl: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4}>
                      <DetailInfo
                        title='Días'
                        description={componente.dias}
                        dataType={DetailDataType.DataKey}
                        dataAlign={DetailAlign.Center}
                        digitos={2}
                      ></DetailInfo>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <DetailInfo
                        title='Horas'
                        description={componente.horas}
                        dataType={DetailDataType.DataKey}
                        dataAlign={DetailAlign.Center}
                        digitos={2}
                      ></DetailInfo>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <DetailInfo
                        title='Minutos'
                        description={componente.minutos}
                        dataType={DetailDataType.DataKey}
                        dataAlign={DetailAlign.Center}
                        digitos={2}
                      ></DetailInfo>
                    </Grid>
                  </Grid>
                </CardMedia>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12}>
              <BitacoraReplicacion idEstacion={data.idEstacion} abrir={openBitacora} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='secondary' disableElevation>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const BitacoraReplicacion = ({ idEstacion, abrir }) => {
  const dispatch = useDispatch();
  const { loading, logs } = useSelector((state) => state.bitacoraReplicacion);
  const filter = {
    idEstacion: idEstacion,
    tipoEvento: 0,
  };

  const handleRefresh = () => {
    dispatch(getLogs(filter)).catch(() => {});
  };
  return (
    <>
      <Bitacora
        title='Eventos'
        data={logs}
        loading={loading.getLogs}
        onRefresh={handleRefresh}
        showFilterData={false}
      />
    </>
  );
};
