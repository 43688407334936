import React from "react";
import { Tooltip } from "@mui/material";

const copiarAlPortapapeles = (texto) => {
    navigator.clipboard.writeText(texto);
};

export const HashAbreviado = ({ data }) => {
    const hashAbrev = data.hash.length > 10 ? data.hash.slice(0, 4) + ".." + data.hash.slice(-4) : data.hash;
    return (
        <>
            <Tooltip title='Copiar  completo' arrow>
                <span style={{ cursor: 'pointer' }} onClick={() => copiarAlPortapapeles(data.hash)}>{hashAbrev}</span>
            </Tooltip>
        </>
    );
};

export default HashAbreviado;
