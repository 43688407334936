import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DetailDialog,
  FilterDrawer,
} from "../../../components/consultas/faccts";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PageViewIcon from "@mui/icons-material/Pageview";
import { state } from "../../../store/consultas/faccts/facCloud";
import {
  clearErrors,
  getfaccloudbitacora,
  setRegistro,
} from "../../../store/consultas/faccts/facCloudBitacora";
import {
  Catalogo,
  ColumnAlign,
  ColumnPriority,
  DangerMessage,
  WarningMessage,
  ColumnType,
} from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ESTADO = (value) =>
  value.estado ? (
    <Tooltip title="Exitoso" arrow>
      <FiberManualRecordIcon fontSize="small" style={{ color: "#00cc00" }} />
    </Tooltip>
  ) : (
    <Tooltip title="Error" arrow>
      <FiberManualRecordIcon fontSize="small" style={{ color: "#FF0000" }} />
    </Tooltip>
  );

const DETALLE = (value) => {
  if (value.detalles != null && value.detalles.trim() !== "") {
    return <PageViewIcon fontSize="small" style={{ color: "#757575" }} />;
  }
};

const EVENTO = (value) =>
  value.tipoEvento === 1 ? (
    <>Ping</>
  ) : value.tipoEvento === 2 ? (
    <>Consulta Ticket</>
  ) : value.tipoEvento === 3 ? (
    <>Consulta Forma de Pago y Uso CFDI</>
  ) : value.tipoEvento === 4 ? (
    <>Registro Factura</>
  ) : value.tipoEvento === 5 ? (
    <>Envio de Correo Factura</>
  ) : value.tipoEvento === 6 ? (
    <>Consulta PDF XML</>
  ) : value.tipoEvento === 7 ? (
    <>Modificación Impresion Factura</>
  ) : value.tipoEvento === 8 ? (
    <>Ping Callback</>
  ) : value.tipoEvento === 9 ? (
    <>Consulta Ticket Callback</>
  ) : value.tipoEvento === 10 ? (
    <>Consulta Forma de Pago y Uso CFDI Callback</>
  ) : value.tipoEvento === 11 ? (
    <>Registro Factura Callback</>
  ) : value.tipoEvento === 12 ? (
    <>Envio de Correo Factura Callback</>
  ) : value.tipoEvento === 13 ? (
    <>Consulta PDF XML Callback</>
  ) : value.tipoEvento === 14 ? (
    <>Modificación Impresion Factura Callback</>
  ) : value.tipoEvento === 15 ? (
    <>Ping Service</>
  ) : (
    <>Monitor Service</>
  );

const FECHA = (value) => {
  var fechaString = value.fechaHora;
  var fecha = new Date(fechaString);
  var anio = fecha.getFullYear();
  var mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
  var dia = fecha.getDate().toString().padStart(2, "0");
  var hora = fecha.getHours().toString().padStart(2, "0");
  var minutos = fecha.getMinutes().toString().padStart(2, "0");
  var segundos = fecha.getSeconds().toString().padStart(2, "0");
  var fechaFormateada = `${dia}/${mes}/${anio} ${hora}:${minutos}:${segundos}`;
  return fechaFormateada;
};

const TIEMPO = (value) => {
  var tiempoString = value.tiempo.toString();
  var partesTiempo = tiempoString.split(":");
  var horas = parseInt(partesTiempo[0]);
  var minutos = parseInt(partesTiempo[1]);
  var segundos = parseFloat(partesTiempo[2]);
  var tiempoEnSegundos = horas * 3600 + minutos * 60 + segundos;
  var tiempoFormateado = `${tiempoEnSegundos.toFixed(3)}`;
  if (tiempoFormateado == "0.000") tiempoFormateado = "";
  return tiempoFormateado;
};

const TABLE_DEFAULT = {
  columns: [
    {
      text: "Fecha",
      name: "fechaHora",
      colAlign: ColumnAlign.Center,
      colPriority: ColumnPriority.High,
      renderCell: FECHA,
    },
    {
      text: "Descripción del evento",
      name: "tipoEvento",
      colAlign: ColumnAlign.Left,
      colPriority: ColumnPriority.High,
      renderCell: EVENTO,
    },
    {
      text: "Segundos",
      name: "tiempo",
      colAlign: ColumnAlign.Left,
      renderCell: TIEMPO,
    },
    {
      text: "Estado",
      name: "estado",
      colAlign: ColumnAlign.Center,
      colPriority: ColumnPriority.High,
      icon: true,
      renderCell: ESTADO,
    },
    {
      name: "detalles",
      linkDetail: true,
      colAlign: ColumnAlign.Center,
      icon: true,
      renderCell: DETALLE,
    },
  ],
};

export function FacCloudBitacora() {
  const dispatch = useDispatch();
  const { registros, error, loading, filter } = useSelector(
    (state) => state.facCloudBitacora
  );
  const { estacion } = useSelector((state) => state.facCloud);

  const modalDetalle = useModal(false);
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (estacion.id === 0) {
      navigate("/consultas/faccts/faccloud");
    }
  });

  const handleRefresh = () => {
    if (estacion.id != 0) {
      dispatch(getfaccloudbitacora({ estacion, filter }));
    }
  };

  const handleSelectRegistro = (id) => {
    const registroEncontrado = registros.find((registro) => registro.id === id);
    let detallesDelRegistro = null;
    if (registroEncontrado) {
      detallesDelRegistro = registroEncontrado.detalles;
    }
    dispatch(setRegistro(detallesDelRegistro));
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo
        title="Eventos de estación en Facturación Cloud"
        subtitle={`${estacion.claveCre} ${estacion.nombreComercial}`}
        table={TABLE_DEFAULT}
        filter={filter}
        filterPanel={<FilterDrawer />}
        data={registros}
        loading={loading.getfaccloudbitacora}
        onDetail={modalDetalle.open}
        onRefresh={handleRefresh}
        onSelect={handleSelectRegistro}
        showFilterData={false}
      />
      <DetailDialog
        open={modalDetalle.isShowing}
        onClose={modalDetalle.close}
        postSubmit={handleRefresh}
      />
      <DangerMessage
        open={dangerMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
      <WarningMessage
        open={warningMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
    </>
  );
}

export default FacCloudBitacora;