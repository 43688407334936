import { createSlice } from "@reduxjs/toolkit";

export const STATES = {
  LOADING: 0,
  AUTHORIZED: 200,
  FORBIDDEN: 403,
};

const initialState = {
  profile: {},
  routes: [
    {
      text: "Inicio",
      route: "/",
      iconName: "Home",
      childrens: [],
      nested: 16,
    },
    {
      text: "Catálogos",
      iconName: "Summarize",
      childrens: [
        {
          text: "Contratos",
          route: "/catalogos/contratos",
          roles: ["ComercialIgas"],
          childrens: [],
          nested: 32,
        },
      ],
    },
    {
      text: "Consultas",
      iconName: "Visibility",
      childrens: [
        {
          text: "Panel de Estaciones",
          route: "/consultas/estaciones",
          childrens: [],
          nested: 32,
        },
        {
          text: "Fac Cloud",
          route: "/consultas/faccts/faccloud",
          roles: ["SoporteIgas"],
          childrens: [],
          nested: 32,
        },
      ],
    },
    {
      text: "Entregables",
      iconName: "Apps",
      childrens: [
        {
          text: "Versiones",
          route: "/entregables/versiones",
          roles: ["SoporteIgas", "DesarrolladorIgas", "TesterIgas"],
          childrens: [],
          nested: 32,
        },
        {
          text: "Ultima Versión",
          route: "/entregables/modulosDev",
          roles: ["SoporteIgas", "DesarrolladorIgas", "TesterIgas"],
          childrens: [],
          nested: 32,
        },
        {
          text: "Entregables por Módulo",
          route: "/entregables/modulos",
          roles: ["SoporteIgas", "DesarrolladorIgas", "TesterIgas"],
          childrens: [],
          nested: 32,
        },
        {
          text: "Tickets",
          route: "/entregables/ticketsDev",
          roles: ["SoporteIgas", "DesarrolladorIgas", "TesterIgas"],
          childrens: [],
          nested: 32,
        },
      ],
    },
    {
      route: "/consultas/faccts/faccloudbitacora",
      roles: ["SoporteIgas"],
    },
    {
      route: "/entregables/detallesDeVersiones",
      roles: ["SoporteIgas", "DesarrolladorIgas", "TesterIgas"],
    },
    {
      route: "/entregables/entregablesModulos",
      roles: ["SoporteIgas", "DesarrolladorIgas", "TesterIgas"],
    },
    {
      route: "/entregables/ticketsModulos",
      roles: ["SoporteIgas", "DesarrolladorIgas", "TesterIgas"],
    },
    {
      route: "/entregables/detallemodulos",
      roles: ["SoporteIgas", "DesarrolladorIgas", "TesterIgas"],
    },
    {
      route: "/entregables/agregarVersion",
      roles: ["SoporteIgas", "DesarrolladorIgas", "TesterIgas"],
    },
  ],
  state: STATES.LOADING,
  error: {},
  backdrop: false,
  progressBarApp: false,
  alertMessage: {},
};

const slice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setBackdrop: (state, action) => {
      state.backdrop = action.payload;
    },
    setProgressBarApp: (state, action) => {
      state.progressBarApp = action.payload;
    },
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload;
    },
  },
});

export default slice.reducer;

export const { setProfile, setState, setBackdrop, setProgressBarApp, setAlertMessage } = slice.actions;
