import { Box, ClickAwayListener, TextField, Typography } from "@mui/material";
import { BotonAction } from "../table";
import { HeaderIGas } from "@paul-igas/igas-react-components";
import { useEffect, useState } from "react";
import { putProductoVersion, setVersiones } from "../../../store/entregables/versiones";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { formatDateTimeMX } from "@paul-igas/igas-react-hooks";

export function HeaderDetalleVersion(props) {
    const {
        initLista = () => { },
        permissionEdit = false,
    } = props;
    const { version } = useSelector((state) => state.versiones);
    const [versionLocal, setVersionLocal] = useState({});
    const [modeEditVersion, setModeChange] = useState(false);
    const dispatch = useDispatch();

    const onClickAway = () => {
        setModeChange(false);
        initialVersionLocal();
    }

    const onChangeMode = () => {
        setModeChange(!modeEditVersion);
        if (modeEditVersion) {
            dispatch(putProductoVersion(versionLocal)).then(hasDispatchError)
                .then(initLista)
                .catch(() => { });
            dispatch(setVersiones(versionLocal));
        }
    };

    const onChangeVersion = (e) => {
        e.preventDefault();
        var param = e.target;
        if (param.id == "noVersion" && param.value.length <= 0) 
            return;
        const versionMod = {
            ...versionLocal,
            [param.id]: param.value
        }
        setVersionLocal(versionMod);
    }

    const initialVersionLocal = () => {
        setVersionLocal(version);
    }

    useEffect(() => {
        initialVersionLocal();
    }, []);
    return (
        <>
            {
                permissionEdit ?
                    (
                        <>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                {
                                    !modeEditVersion ? (
                                        <>
                                            <Typography variant='h5'>{`${versionLocal.producto} ${versionLocal.noVersion}`}</Typography>
                                            <BotonAction
                                                title={"Editar versión"}
                                                onClick={onChangeMode}
                                                action={2}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <ClickAwayListener onClickAway={onClickAway}>
                                                <div>
                                                    <TextField
                                                        id="producto"
                                                        variant="standard"
                                                        label="Producto"
                                                        value={versionLocal.producto}
                                                        onChange={(e) => onChangeVersion(e)}
                                                    />
                                                    <TextField
                                                        id="noVersion"
                                                        variant="standard"
                                                        label="No. Versión"
                                                        value={versionLocal.noVersion}
                                                        onChange={(e) => onChangeVersion(e)}
                                                    />
                                                    <BotonAction
                                                        title={"Guardar cambios..."}
                                                        onClick={onChangeMode}
                                                        action={3}
                                                    />
                                                </div>
                                            </ClickAwayListener>
                                        </>
                                    )
                                }
                            </Box>
                            <Box>
                                <Typography variant='subtitle2' color='InactiveCaptionText'>{`Fecha de publicación: ${formatDateTimeMX(versionLocal.publicacion ?? new Date())}`}</Typography>
                            </Box>
                        </>
                    ) :
                    (
                        <HeaderIGas
                            title={`${versionLocal.producto} ${versionLocal.noVersion}`}
                            subtitle={`Fecha de publicación: ${formatDateTimeMX(versionLocal.publicacion ?? new Date())}`}
                        />
                    )
            }
        </>
    );
}
