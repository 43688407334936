import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import { DeleteEntregable, GetEntregablesByVersion, GetTokenEntregables, PostEntregable } from "../../api/services/entregables/versiones";

export const getVersionesEntregables = createAsyncThunk(
  "api/Versiones/EntregablesVersion/",
  async (params, { rejectWithValue }) => {
    try {
      return await GetEntregablesByVersion(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const getTokenEntregables = createAsyncThunk(
  "api/Versiones/TokenEntregables/",
  async (params, { rejectWithValue }) => {
    try {
      return await GetTokenEntregables(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const postEntregables = createAsyncThunk(
  "postEntregables/",
  async (params, { rejectWithValue }) => {
    try {
      return await PostEntregable(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const deleteEntregables = createAsyncThunk(
  "deleteTicket/idEntregable",
  async (params, { rejectWithValue }) => {
    try {
      return await DeleteEntregable(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const initialState = {
  entregable: {},
  entregables: [],
  errorEntregables: {},
  filter: {
    evento: { state: false, value: 0 },
  },
  loadingEntregables: {
    getVersionesEntregables: false,
  },
};

const slice = createSlice({
  name: "versionesEntregables",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setEntregable: (state, action) => {
      state.entregable = action.payload;
    },
    clearErrorsEntregables: (state) => {
      state.errorEntregables = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getVersionesEntregables.pending, (state) => {
      state.loadingEntregables.getVersionesEntregables = true;
    }),
      builder.addCase(getVersionesEntregables.fulfilled, (state, action) => {
        state.errorEntregables = {};
        state.loadingEntregables.getVersionesEntregables = false;
        state.entregables = action.payload;
      }),
      builder.addCase(getVersionesEntregables.rejected, (state, action) => {
        state.errorEntregables = action.payload;
        state.loadingEntregables.getVersionesEntregables = false;
        state.entregables = [];
        state.ticketsDeVersion = [];
      });
  },
});

export default slice.reducer;

export const { setFilter, clearErrorsEntregables, setEntregable } = slice.actions;
