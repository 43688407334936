import Oidc, { UserManager } from "oidc-client"
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Anonymous } from "../../layout/auth";
import { Loading } from "./loading";

const signinCallback = async (navigate) => {
    new UserManager({
        userStore: new Oidc.WebStorageStateStore({ store: window.localStorage })
    })
        .signinRedirectCallback()
        .then(function () {
            navigate('/');
        }).catch(function (e) {
            console.error(e);
        });
}

export const SigninOidc = () => {
    let navigate = useNavigate();

    useEffect(() => {
        signinCallback(navigate);
    });

    return (<Anonymous><Loading /></Anonymous>)
}