import api from "../service";

export async function GetfacCloud(params) {
  const res = await api.get("bitacorafaccts/estaciones", { params });
  return res.data;
}

export async function GetfacCloudBitacora(params) {
  const fechaActual = new Date();
  const fechaInicial = new Date(
    fechaActual.getFullYear(),
    fechaActual.getMonth(),
    1
  );
  const fechaFinal = new Date(
    fechaActual.getFullYear(),
    fechaActual.getMonth() + 1,
    0
  );

  const formatfechaInicial = fechaInicial.toISOString().split("T")[0]; // Primer día del mes
  const formatfechaFinal = fechaFinal.toISOString().split("T")[0]; // Último día del mes
  var tipo =
    params.filter.evento.state != false ? params.filter.evento.value : 99;
  const res = await api.get(
    `bitacorafaccts/bitacora/${params.estacion.id}/${formatfechaInicial}/${formatfechaFinal}/${tipo}`
  );
  return res.data;
}
