import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  ColumnAlign,
  ColumnPriority,
  IGasForm,
  IGasSelectField,
  IGasTextField,
  LoadingPanel,
  SuccessMessage,
} from "@paul-igas/igas-react-components";
import { hasDispatchError } from "../../../api/services/service";
import { getAll } from "../../../store/consultas/usuarioestacion";
import { wait } from "../../helpers/utils";
import { getId, update } from "../../../store/catalogos/suscripciones";
import EstacionesContrato from "./components/estacionesContrato";
import { getModelErrors, useModal } from "@paul-igas/igas-react-hooks";

const TABLE_UPDATE = {
  columns: [
    { text: "Estación", name: "nombre" },
    { text: "CRE", name: "cre", colPriority: ColumnPriority.Low },
    { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
  ],
};

export function UpdateDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const { loading, suscripcionIdSelected, suscripcion, error } = useSelector((state) => state.subscriptions);
  const { administradores } = useAdministradores(open);
  const [errors, setErrors] = useState({});
  const data = useEstacionesUpdate(open);

  const successMsg = useModal(false);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const onSubmit = (form) => {
    dispatch(update({ ...form, id: suscripcionIdSelected, estaciones: data.stations }))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const initDialog = () => {
    if (open === true) {
      setErrors([]);
      dispatch(getId(suscripcionIdSelected))
        .then(hasDispatchError)
        .then(({ payload }) => data.initialize(payload.estaciones))
        .catch(onClose);
    }
  };

  const assignErrors = () => {
    if (error?.status === 400) setErrors(getModelErrors(error.message));
  };

  useEffect(initDialog, [open]);
  useEffect(assignErrors, [error]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='sm' scroll='paper'>
        {loading.update && <LinearProgress />}
        <DialogTitle>Editar Contrato</DialogTitle>
        <DialogContent dividers>
          {loading.getId && <LoadingPanel />}
          {!loading.getId && (
            <>
              <IGasForm id='form-subs-edit-dlg' onSubmit={onSubmit} autoComplete='off'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <IGasTextField
                      autoFocus={true}
                      name='descripcion'
                      defaultValue={suscripcion?.descripcion ?? ""}
                      label='Descripción'
                      error={errors?.descripcion}
                      maxLength={100}
                      disabled={loading.update}
                    />
                  </Grid>

                  {administradores.length <= 1 && (
                    <Grid item xs={12} sm={12}>
                      <IGasTextField
                        name='administrador'
                        defaultValue={suscripcion?.administrador ?? administradores[0]?.correo}
                        label='Email usuario administrador'
                        error={errors?.administrador}
                        maxLength={100}
                        disabled
                      />
                    </Grid>
                  )}

                  {administradores.length > 1 && (
                    <Grid item xs={12} sm={12}>
                      <IGasSelectField
                        name='administrador'
                        defaultValue={suscripcion?.administrador}
                        label='Administrador'
                        disabled={loading.update}
                      >
                        {administradores.map((i) => (
                          <MenuItem key={i.id} value={i.correo}>
                            {i.correo}
                          </MenuItem>
                        ))}
                      </IGasSelectField>
                    </Grid>
                  )}
                </Grid>
              </IGasForm>

              {/* Componente para administrar listado de estaciones */}
              <EstacionesContrato data={data} table={TABLE_UPDATE} />
              {Boolean(errors?.estaciones) && (
                <Typography
                  variant='caption'
                  marginLeft={2}
                  style={{ color: "red" }}
                >{`${errors?.estaciones}`}</Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.update || loading.getId} onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            color='secondary'
            disableElevation
            disabled={loading.update || loading.getId}
            type='submit'
            form='form-subs-edit-dlg'
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se guardó correctamente.' />
    </>
  );
}

export default UpdateDialog;

const useEstacionesUpdate = (open) => {
  const [stations, setStations] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const deleteStation = (station) => {
    let newStations = stations.filter((st) => st.id !== station.id);
    setStations(newStations);
  };

  const refreshStations = async () => {
    if (stations.length > 0) {
      setRefresh(true);
      await wait(1000);
    }
    setRefresh(false);
  };

  const initStations = () => {
    if (open === true) setStations([]);
  };

  useEffect(initStations, [open]);
  useEffect(() => {
    refreshStations();
  }, [stations]);

  return {
    stations,
    refresh,
    initialize: (stations) => setStations(stations),
    addStation: (station) => setStations([...stations, station]),
    deleteStation,
  };
};

const useAdministradores = (open) => {
  const dispatch = useDispatch();
  const { suscripcionIdSelected } = useSelector((state) => state.subscriptions);
  const [administradores, setAdministradores] = useState([]);

  const initAdmin = () => {
    if (open) {
      setAdministradores([]);
      dispatch(getAll(suscripcionIdSelected))
        .then(hasDispatchError)
        .then(({ payload }) => setAdministradores(payload))
        .catch(() => {});
    }
  };

  useEffect(initAdmin, [open]);

  return {
    administradores,
  };
};
