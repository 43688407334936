import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { logout } from "../../../../api/authService";
import { UseAuthContext } from "../../../../context/auth";

export default function LogoutDialog() {
    const context = UseAuthContext();

    async function handleLogout() {
        context.setBackdrop(true);
        await logout();
    }

    return (
        <Dialog open={context.logoutDialog.isShowing} onClose={context.logoutDialog.open}>
            <DialogTitle>Cerrar Sesión</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    ¿Desea terminar la sesión del usuario {context.profile.name}?
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={context.logoutDialog.close} color='secondary'>Cancelar</Button>
                <Button onClick={handleLogout} color='secondary'>Aceptar</Button>
            </DialogActions>
        </Dialog>
    )
}