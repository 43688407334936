import { Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { getId, remove } from "../../../store/catalogos/suscripciones";
import { useModal } from "@paul-igas/igas-react-hooks";
import { SuccessMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { boldText } from "../../helpers/utils";

export function DeleteDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const { loading, suscripcionIdSelected, suscripcion } = useSelector((state) => state.subscriptions);
  const successMsg = useModal(false);
  const warningMsg = useModal(false);

  const initDialog = () => {
    if (open) {
      dispatch(getId(suscripcionIdSelected)).then(hasDispatchError).catch(onClose);
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    onClose();
  };

  const onSubmit = () => {
    if (suscripcion.estaciones <= 0) {
      dispatch(remove(suscripcionIdSelected))
        .then(hasDispatchError)
        .then(onClose)
        .then(successMsg.open)
        .then(postSubmit)
        .catch(() => {});
    } else {
      warningMsg.open();
      onClose();
    }
  };

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
        <DialogTitle>Eliminar Suscripción</DialogTitle>
        <DialogContent dividers>
          {loading.getId && <>Cargando...</>}
          {!loading.getId && (
            <DialogContentText>¿Desea eliminar la suscripción {boldText(suscripcion?.descripcion)}?</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disabled={loading.delete} onClick={onSubmit}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se eliminó correctamente.' />
      <WarningMessage
        open={warningMsg.isShowing}
        onClose={warningMsg.close}
        text='La suscripción tiene estaciones vinculadas.'
      />
    </>
  );
}
