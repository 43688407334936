import api from "../service";

const _controller = "entregables/Versiones";
export async function GetVersiones() {
  const response = await api.get(_controller);
  return response.data;
}

export async function GetVersionesById(params) {
  const response = await api.get(`${_controller}/ByModuloId/${params}`);
  return response.data;
}

export async function GetEntregablesByVersion(params) {
  const response = await api.get(`${_controller}/EntregablesVersion/${params.id}`);
  return response.data;
}

export async function GetTokenEntregables(params) {
  const response = await api.get(`${_controller}/TokenEntregables/${params}`);
  return response.data;
}

export async function GetTicketsByVersion(params) {
  const response = await api.get(`${_controller}/TicketsVersion/${params.id}`);
  return response.data;
}

export async function GetTokenTickets(params) {
  const response = await api.get(`${_controller}/TokenTickets/${params}`);
  return response.data;
}

export async function InsertVersion(params) {
  const response = await api.post(`${_controller}`, params);
  return response.data;
}

export async function DeleteVersion(params) {
  const response = await api.delete(`${_controller}/${params}`);
  return response.data;
}

export async function GetVersionId(params) {
  const response = await api.get(`${_controller}/${params}`);
  return response.data;
}

export async function PutVersion(params) {
  const response = await api.put(`${_controller}`,params);
  return response.data;
}

export async function PutProductoVersion(params) {
  const response = await api.put(`${_controller}/ProductoVersion`, params);
  return response.data;
}

export async function PostEntregable(params) {
  const response = await api.post(`${_controller}/Entregable`,params);
  return response.data;
}

export async function DeleteEntregable(params) {
  const response = await api.delete(`${_controller}/Entregable/${params}`);
  return response.data;
}

export async function PostTicket(params) {
  const response = await api.post(`${_controller}/Ticket`,params);
  return response.data;
}

export async function DeleteTicket(params) {
  const response = await api.delete(`${_controller}/Ticket/${params}`);
  return response.data;
}


export default { GetVersiones, GetEntregablesByVersion, GetTicketsByVersion };