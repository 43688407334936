import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetLogs } from "../../api/services/consultas/bitacoraReplicacion";
import { hasErrorRedux } from "../../api/services/service";

export const getLogs = createAsyncThunk("bitacoraReplicacion/getLogs", async (params, { rejectWithValue }) => {
  try {
    return await GetLogs(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});


export const initialState = {
  logs: [],
  error: {},
  loading: {
    getLogs: false,
  },
};

const slice = createSlice({
  name: "bitacoraReplicacion",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getLogs.pending, (state) => {
      state.loading.getLogs = true;
    });
    builder.addCase(getLogs.fulfilled, (state, action) => {
      state.error = {};
      state.loading.getLogs = false;
      state.logs = action.payload;
    });
    builder.addCase(getLogs.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getLogs = false;
      state.logs = [];
    });
  },
});

export default slice.reducer;

export const { clearErrors } = slice.actions;
