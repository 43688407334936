import { AddCircle, RemoveCircle, Search } from "@mui/icons-material";
import { Card, CardHeader, Grid, IconButton, Tooltip } from "@mui/material";
import { SimpleTable } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { ConsultarEstacion, RegistrarEstacion } from "../../estaciones";

export const EstacionesContrato = ({ data, table = [], showActions = true }) => {
  const { stations, refresh, addStation = () => {}, deleteStation = () => {} } = data;
  const consultarEstacionDlg = useModal(false);
  const registrarEstacionDlg = useModal(false);

  return (
    <>
      <Card sx={{ mt: "10px" }} style={{ border: "none", boxShadow: "none" }}>
        <CardHeader
          sx={{ m: "-10px" }}
          title='Estaciones'
          action={
            showActions && (
              <>
                <Tooltip title='Registrar Estación'>
                  <IconButton color='primary' onClick={() => registrarEstacionDlg.open()}>
                    <AddCircle />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Asignar Estación'>
                  <IconButton color='primary' onClick={() => consultarEstacionDlg.open()}>
                    <Search />
                  </IconButton>
                </Tooltip>
              </>
            )
          }
        />
      </Card>

      <Grid container sx={{ mt: "5px" }}>
        <Grid item xs={12}>
          <SimpleTable
            table={table}
            data={stations}
            loading={refresh}
            showPagination={false}
            rowOptions={<StationOptions onDeleteStation={deleteStation} />}
          />
        </Grid>
      </Grid>

      <RegistrarEstacion open={registrarEstacionDlg.isShowing} onClose={registrarEstacionDlg.close} />
      <ConsultarEstacion
        open={consultarEstacionDlg.isShowing}
        onClose={consultarEstacionDlg.close}
        postSubmit={addStation}
        stations={stations}
      />
    </>
  );
};

export default EstacionesContrato;

const StationOptions = (props) => {
  const { data = {}, onDeleteStation = () => {} } = props;

  const handleDelete = () => {
    onDeleteStation(data);
  };

  if (data?.estatusOperativo > 0) return null;

  return (
    <IconButton size='small' color='error' onClick={handleDelete}>
      <Tooltip title='Quitar estación'>
        <RemoveCircle />
      </Tooltip>
    </IconButton>
  );
};
