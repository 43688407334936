import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetfacCloudBitacora } from "../../../api/services/consultas/facCloud";
import { hasErrorRedux } from "../../../api/services/service";

export const getfaccloudbitacora = createAsyncThunk(
  "bitacorafaccts/bitacora",
  async (params, { rejectWithValue }) => {
    try {
      return await GetfacCloudBitacora(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const initialState = {
  registro: {},
  registros: [],
  error: {},
  filter: {
    evento: { state: false, value: 3, label: "Tipo Evento" },
  },
  loading: {
    getfaccloudbitacora: false,
  },
};

const slice = createSlice({
  name: "facCloudBitacora",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setRegistro: (state, action) => {
      state.registro = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getfaccloudbitacora.pending, (state) => {
      state.loading.getfaccloudbitacora = true;
    }),
      builder.addCase(getfaccloudbitacora.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getfaccloudbitacora = false;
        state.registros = action.payload;
      }),
      builder.addCase(getfaccloudbitacora.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getfaccloudbitacora = false;
        state.registros = [];
      });
  },
});

export default slice.reducer;

export const { setFilter, clearErrors, setRegistro } = slice.actions;
