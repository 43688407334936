import { hasErrorRedux } from "../../../api/services/service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetModulosDev } from "../../../api/services/entregables/modulosDev";

export const getModulos = createAsyncThunk(
  "api/Modulos/LastByModulo",
  async (params, { rejectWithValue }) => {
    try {
      return await GetModulosDev(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const initialState = {
  modulo: { id: 0 },
  modulos: [],
  descripcion: "",
  error: {},
  loading: {
    getModulos: false,
  },
};

const slice = createSlice({
  name: "modulosDev",
  initialState,
  reducers: {
    setModulo: (state, action) => {
      state.modulo = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getModulos.pending, (state) => {
      state.loading.getModulos = true;
    }),
      builder.addCase(getModulos.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getModulos = false;
        state.modulos = action.payload;
      }),
      builder.addCase(getModulos.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getModulos = false;
        state.modulos = [];
      });
  },
});

export default slice.reducer;

export const { clearErrors, setModulo } = slice.actions;
