import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { FlatButton } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useDispatch } from "react-redux";
import { setLicencia } from "../../../store/consultas/licencias";
import DetailLicencia from "./dialogs/detailLicencia";
import { Tooltip } from "@mui/material";

export const OptionLicencia = ({ data }) => {
  const dispatch = useDispatch();
  const modalDetail = useModal(false);

  function obtenerDescripcionModulos(modulosAutorizados) {
    if (!modulosAutorizados || !Array.isArray(modulosAutorizados)) {
      return "No hay módulos autorizados";
    }
    const descripciones = modulosAutorizados.map((modulo) => modulo.descripcion);
    return descripciones.join(", ");
  }

  const handleClick = () => {
    dispatch(
      setLicencia({
        idEstacion: data.idEstacion,
        cre: data.cre,
        nombre: data.nombre,
        razonSocial: data.razonSocial,
        fechaRegistro: data.licenciaEstacion.fechaRegistro,
        vigencia: data.licenciaEstacion.vigencia,
        prorroga: data.licenciaEstacion.prorroga,
        modulosAutorizados: obtenerDescripcionModulos(data.licenciaEstacion.modulosAutorizados),
        estatus: data.licenciaEstacion.estatus,
      })
    );
    modalDetail.open();
  };

  if (data.licenciaEstacion == undefined)
    return (
      <Tooltip title='Sin Licencia' arrow>
        <FiberManualRecordIcon fontSize='small' style={{ color: "#9b9b9b" }} />
      </Tooltip>
    );

  return (
    <>
      <Tooltip title='Detalle de licencia' arrow>
        <FlatButton onClick={handleClick}>
          <BotonLicencia estatus={data.licenciaEstacion.estatus} />
        </FlatButton>
      </Tooltip>
      <DetailLicencia open={modalDetail.isShowing} onClose={modalDetail.close} postSubmit={() => {}} />
    </>
  );
};

export default OptionLicencia;

const BotonLicencia = ({ estatus }) => {
  if (estatus === 1) return <FiberManualRecordIcon fontSize='small' style={{ color: "#00cc00" }} />;
  if (estatus === 2) return <FiberManualRecordIcon fontSize='small' style={{ color: "#000000" }} />;
  if (estatus === 3) return <FiberManualRecordIcon fontSize='small' style={{ color: "#F3F781" }} />;
  if (estatus === 4) return <FiberManualRecordIcon fontSize='small' style={{ color: "#FF0000" }} />;
  return <FiberManualRecordIcon fontSize='small' style={{ color: "#FF8000" }} />;
};
