import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAll, SendConfirmEmail } from "../../api/services/catalogos/usuarioEstacion";
import { hasErrorRedux } from "../../api/services/service";

export const sendConfirmEmail = createAsyncThunk("usuarioEstacion/enviarEmail", async (params, { rejectWithValue }) => {
  try {
    return await SendConfirmEmail(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getAll = createAsyncThunk("usuarios/selectAll", async (params, { rejectWithValue }) => { 
  try {
    return await GetAll(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const initialState = {
  error: {},
  users:[],
  filter: {
    activo: { state: true, value: true, label: "Activo" },
    confirmado: { state: false, value: false, label: "Confirmado" },
    role: { state: false, value: "Administrador", label: "Rol" },
  },
  loading: {
    getAll: false,
  },
};

const slice = createSlice({
  name: "usuarios",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.loading.getAll = true;
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.error = {};
      state.loading.getAll = false;
      state.users = action.payload;
    });
    builder.addCase(getAll.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getAll = false;
      state.users = [];
    });
  },
});

export default slice.reducer;
