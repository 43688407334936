import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { IGasForm, IGasSelectField, IGasTextField } from "@paul-igas/igas-react-components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    getmodulos,
} from "../../../../store/entregables/modulos";
import { hasDispatchError } from "../../../../api/services/service";

export const RegistrarEntregable = ({ open, onClose, data, postSubmit = () => { } }) => {
    const dispatch = useDispatch();

    const [errors, setErrors] = useState({});
    const [listaModulos, setListaModulos] = useState([]);

    const { modeloEntregables} = data

    const handleClose = (reason) => {
        if (reason && reason === "backdropClick") return;
        setErrors({});
        onClose();
    };
    const handleSave = (form) => {
        const formularioArreglado = changeForm(form);
        if (validateSubmit(formularioArreglado)) {
            return;
        } else {
            const nuevoArreglo = [...modeloEntregables, { index: Math.max(...modeloEntregables.map(entregable => entregable.index || 0), 0) + 1, ...formularioArreglado }];
            postSubmit(nuevoArreglo);
            handleClose();
        }
    }
    const changeForm = (dataFormulario) => {
        const moduloEncontrado = listaModulos.find(modulo => modulo.id === dataFormulario.descripcion);
        if (moduloEncontrado) {
            dataFormulario.moduloId = moduloEncontrado.id;
            dataFormulario.descripcion = moduloEncontrado.descripcion;
        }
        return dataFormulario;
    };
    const validateSubmit = (dataFormulario) => {
        const newErrors = [];
        if (dataFormulario.hash.length < 64 || dataFormulario.hash.length > 64) {
            newErrors.hash = "Hash debe ser igual a 64 caracteres."
        }
        setErrors(newErrors);
        return !Object.values(newErrors).every((x) => x === "");
    }
    const actualizaListaModulos = ({ payload }) => {
        setListaModulos(payload);
    }
    const initLista = () => {
        dispatch(getmodulos())
            .then(hasDispatchError)
            .then(actualizaListaModulos)
            .catch(() => { })
    }
    useEffect(() => {
        initLista();
    }, []);
    return (
        <>
            <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='sm' fullWidth scroll='paper'>
                <DialogTitle >Registrar Entregable</DialogTitle>
                <DialogContent dividers={true}>
                    <IGasForm id="registrar-entregable-dlg" onSubmit={handleSave}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <IGasSelectField
                                    label='Modulos'
                                    name={'descripcion'}
                                    defaultValue={""}
                                >
                                    {listaModulos.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.descripcion}
                                        </MenuItem>
                                    ))}
                                </IGasSelectField>
                            </Grid>
                            <Grid item xs={12} >
                                <IGasTextField label='Path' name='path' maxLength={100} defaultValue="" />
                            </Grid>
                            <Grid item xs={12}>
                                <IGasTextField label='Hash' error={errors?.hash} name='hash' maxLength={64} defaultValue="" />
                            </Grid>
                        </Grid>
                    </IGasForm>
                </DialogContent>
                <DialogActions>
                    <Button
                        type='submit'
                        color='secondary'
                        form='registrar-entregable-dlg'
                        disableElevation
                    >
                        Guardar
                    </Button>
                    <Button color='secondary' disableElevation onClick={handleClose}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}