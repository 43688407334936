import { hasErrorRedux } from "../../../api/services/service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetTicketsDev } from "../../../api/services/entregables/ticketsDev";

export const getTickets = createAsyncThunk(
  "api/versiones/LastByTickets",
  async (params, { rejectWithValue }) => {
    try {
      return await GetTicketsDev(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const initialState = {
  ticket: { id: 0 },
  tickets: [],
  descripcion: "",
  error: {},
  loading: {
    getTickets: false,
  },
};

const slice = createSlice({
  name: "ticketsDev",
  initialState,
  reducers: {
    setTicket: (state, action) => {
      state.ticket = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getTickets.pending, (state) => {
      state.loading.getTickets = true;
    }),
      builder.addCase(getTickets.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getTickets = false;
        state.tickets = action.payload;
      }),
      builder.addCase(getTickets.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getTickets = false;
        state.tickets = [];
      });
  },
});

export default slice.reducer;

export const { clearErrors, setTicket } = slice.actions;
