import {
  Catalogo,
  ColumnAlign,
  ColumnType,
  DangerMessage,
  WarningMessage,
} from "@paul-igas/igas-react-components";

import {
  clearErrors,
  setModulo,
  getModulos,
} from "../../../store/consultas/Modulos/modulosDev";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "@paul-igas/igas-react-hooks";
import { HashAbreviado, OptionEntregables } from "../../../components/entregables";
import { setVersiones } from "../../../store/entregables/versiones";

const CELL_DESCARGA = (value) => <OptionEntregables data={value} />;
const HASH_COMPLET = (value) => <HashAbreviado data={value} />;

const TABLE_MODULOS = {
  columns: [
    {
      text: "Módulo",
      name: "moduloDescripcion",
      colAlign: ColumnAlign.Left,
    },
    {
      text: "Versión",
      name: "noVersion",
      colAlign: ColumnAlign.Left,
      linkDetail: true,
    },
    {
      text: "Publicación",
      name: "publicacion",
      colAlign: ColumnAlign.Left,
      colType: ColumnType.Date,
    },
    {
      text: "Código Hash",
      name: "hash",
      colAlign: ColumnAlign.Left,
      renderCell: HASH_COMPLET,
    },
    {
      text: "Descarga",
      name: "pathEntregables",
      colAlign: ColumnAlign.Center,
      icon: true,
      renderCell: CELL_DESCARGA,
    },
  ],
};

export function ModulosDev() {
  const dispatch = useDispatch();

  const { loading, modulos, error } = useSelector((state) => state.modulosDev);

  const navigate = useNavigate();
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const handleRefresh = () => {
    setModulo([]);
    dispatch(getModulos());
  };

  const handleSelectModule = (id) => {
    const versionSeleccionada = modulos.find((modulo) => modulo.id === id);
    const version = {
      id: versionSeleccionada.versionId,
      producto: versionSeleccionada.producto,
      noVersion: versionSeleccionada.noVersion,
      publicacion: versionSeleccionada.publicacion,
      estado : versionSeleccionada.estado,
      descripcionEstado : versionSeleccionada.descripcionEstado
    };
    dispatch(setVersiones(version));
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleNav = () => {
    navigate("/entregables/detallesDeVersiones");
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo
        title="Ultima Versión por Módulo"
        table={TABLE_MODULOS}
        data={modulos}
        loading={loading.getModulos}
        onDetail={handleNav}
        onRefresh={handleRefresh}
        onSelect={handleSelectModule}
        showPagination={false}
      />
      <DangerMessage
        open={dangerMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
      <WarningMessage
        open={warningMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
    </>
  );
}

export default ModulosDev;
