import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeleteVersion, GetVersionId, GetVersiones, InsertVersion, PutProductoVersion, PutVersion } from "../../api/services/entregables/versiones";
import { hasErrorRedux } from "../../api/services/service";

export const getversiones = createAsyncThunk("api/Versiones/", async (_, { rejectWithValue }) => {
  try {
    return await GetVersiones();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const insertVersion = createAsyncThunk("api/Versiones/insert", async (params, { rejectWithValue }) => {
  try {
    return await InsertVersion(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const deleteVersion = createAsyncThunk("api/Versiones/DeleteVersion", async (params, { rejectWithValue }) => {
  try {
    return await DeleteVersion(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getVersionId = createAsyncThunk("api/Versiones/Id", async (params, { rejectWithValue }) => {
  try {
    return await GetVersionId(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const putVersion = createAsyncThunk("api/Versiones/modificar", async (params, { rejectWithValue }) => {
  try {
    return await PutVersion(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const putProductoVersion = createAsyncThunk("putProductoVersion/", async (params, { rejectWithValue }) => {
  try {
    return await PutProductoVersion(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const initialState = {
  version: { id: 0 },
  versiones: [],
  versionModify: {},
  error: {},
  loading: {
    getversiones: false,
    insert: false,
    getVersionId: false,
  },
};

const slice = createSlice({
  name: "versiones",
  initialState,
  reducers: {
    setVersiones: (state, action) => {
      state.version = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getversiones.pending, (state) => {
      state.loading.getversiones = true;
    });
    builder.addCase(getversiones.fulfilled, (state, action) => {
      state.error = {};
      state.loading.getversiones = false;
      state.versiones = action.payload;
    });
    builder.addCase(getversiones.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getversiones = false;
      state.versiones = [];
    });
    // Registrar
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(insertVersion.pending, (state) => {
      state.loading.insert = true;
    });
    builder.addCase(insertVersion.fulfilled, (state) => {
      state.error = {};
      state.loading.insert = false;
    });
    builder.addCase(insertVersion.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.insert = false;
    });
    // Delete
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(deleteVersion.pending, (state) => {
      state.loading.getversiones = true;
    });
    builder.addCase(deleteVersion.fulfilled, (state) => {
      state.error = {};
      state.loading.getversiones = false;
    });
    builder.addCase(deleteVersion.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getversiones = false;
    });
    // GetVersionId
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getVersionId.pending, (state) => {
      state.loading.getVersionId = true;
    });
    builder.addCase(getVersionId.fulfilled, (state, action) => {
      state.error = {};
      state.loading.getVersionId = false;
      state.versionModify = action.payload;
    });
    builder.addCase(getVersionId.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getVersionId = false;
    });
    // Modificar
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(putVersion.pending, (state) => {
      state.loading.insert = true;
    });
    builder.addCase(putVersion.fulfilled, (state) => {
      state.error = {};
      state.loading.insert = false;
    });
    builder.addCase(putVersion.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.insert = false;
    });
  },
});


export default slice.reducer;

export const { clearErrors, setVersiones } = slice.actions;