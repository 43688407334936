import { Route, Routes } from "react-router-dom";
import AuthRoute from "./components/authRoute";
import { NotFound, SigninOidc, SignoutOidc } from "./pages/auth";
import Dashboard from "./pages/dashboard";
import { Suscripciones } from "./pages/catalogos";
import { PanelEstaciones } from "./pages/consultas";
import { FacCloud, FacCloudBitacora } from "./pages/consultas/faccts";
import {
  VersionesPage,
  DetallesVersionesPage,
  Modulos,
  DetalleModulo,
  AgregarVersionPage,
  ModulosDev,
  TicketsDev
} from "./pages/entregables";

export default function App() {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        <Route path='/' element={<Dashboard />} />
        <Route path='/catalogos/contratos' element={<Suscripciones />} />
        <Route path='/consultas/estaciones' element={<PanelEstaciones />} />

        <Route path='/consultas/faccts/faccloud' element={<FacCloud />} />
        <Route path='/consultas/faccts/faccloudbitacora' element={<FacCloudBitacora />} />

        <Route path='/entregables/versiones' element={<VersionesPage />} />
        <Route path='/entregables/detallesDeVersiones' element={<DetallesVersionesPage />} />
        <Route path='/entregables/agregarVersion' element={<AgregarVersionPage />} />
        <Route path='/entregables/modulosDev' element={<ModulosDev />} />
        <Route path='/entregables/modulos' element={<Modulos />} />
        <Route path='/entregables/detallemodulos' element={<DetalleModulo />} />
        <Route path='/entregables/ticketsDev' element={<TicketsDev />} />

        {/* En caso de que no exista la ruta, se carga esta página */}
        <Route path='*' element={<NotFound />} />
      </Route>

      <Route path='/signin-oidc' element={<SigninOidc />} />
      <Route path='/signout-oidc' element={<SignoutOidc />} />
    </Routes>
  );
}
