import {
  Catalogo,
  ColumnAlign,
  ColumnType,
  DangerMessage,
  WarningMessage,
} from "@paul-igas/igas-react-components";

import {
  clearErrors,
  getTickets,
  setTicket,
} from "../../../store/consultas/Tickets/ticketsDev";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "@paul-igas/igas-react-hooks";
import { IconoTipoTicket, OptionTableRow } from "../../../components/entregables";
import { setVersiones } from "../../../store/entregables/versiones";

const TIPO_CASO = (value) => <IconoTipoTicket data={{ tipo: value.ticketTipo }} />;

const TABLE_MODULOS = {
  columns: [
    {
      text: "",
      name: "ticketTipo",
      colAlign: ColumnAlign.Center,
      renderCell: TIPO_CASO,
    },
    {
      text: "Desarrollo",
      name: "ticketDev",
      colAlign: ColumnAlign.Left,
    },
    {
      text: "Soporte",
      name: "ticketSoporte",
      colAlign: ColumnAlign.Left,
    },
    {
      text: "Descripción",
      name: "ticketDescripcion",
      colAlign: ColumnAlign.Left,
    },
    {
      text: "Versión",
      name: "noVersion",
      colAlign: ColumnAlign.Left,
      linkDetail: true,
    },
    {
      text: "Publicación",
      name: "publicacion",
      colAlign: ColumnAlign.Left,
      colType: ColumnType.Date,
    },
    {
      text: "",
      name: "action",
      colAlign: ColumnAlign.Center,
      options: true,
      icon: true,
    },
  ],
};

export function TicketsDev() {
  const dispatch = useDispatch();

  const { loading, tickets, error } = useSelector((state) => state.ticketsDev);
  const [ticketsOfVersion] = useState([]);

  const navigate = useNavigate();
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const modalEdit = useModal(false);
  const modalDelete = useModal(false);
  const modalDetalle = useModal(false);

  const handleRefresh = () => {
    setTicket([]);
    dispatch(getTickets());
  };

  const handleSelectVersion = (id) => {
    const versionSeleccionada = tickets.find((ticket) => ticket.id === id);
    const version = {
      id: versionSeleccionada.versionId,
      producto: versionSeleccionada.producto,
      noVersion: versionSeleccionada.noVersion,
      publicacion: versionSeleccionada.publicacion,
      estado: versionSeleccionada.estado,
      descripcionEstado : versionSeleccionada.descripcionEstado
    };
    dispatch(setVersiones(version));
  };

  const handleSelectRegistroTicket = (id) => {
    const registroEncontrado = ticketsOfVersion.find(
      (registro) => registro.id === id
    );
    dispatch(setTicket(registroEncontrado));
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleNav = () => {
    navigate("/entregables/detallesDeVersiones");
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo
        title="Tickets"
        table={TABLE_MODULOS}
        data={tickets}
        loading={loading.getTickets}
        onDetail={handleNav}
        onRefresh={handleRefresh}
        onSelect={handleSelectVersion}
        rowOptions={
          <OptionTableRow
            onEdit={modalEdit.open}
            onDelete={modalDelete.open}
            onDetalle={modalDetalle.open}
            onSelect={handleSelectRegistroTicket}
          />
        }
      />
      <DangerMessage
        open={dangerMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
      <WarningMessage
        open={warningMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
    </>
  );
}

export default TicketsDev;
