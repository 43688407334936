import React, { useState } from "react";
import { Button, Menu, MenuItem, ListItemIcon, Typography, ListItemText } from "@mui/material";
import { MoreVert, Edit, Backspace, Pageview, ExpandMore, Email } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setSuscripcionIdSelected } from "../../../../store/catalogos/suscripciones";
import { useTheme } from "@emotion/react";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { useModal, usePopper } from "@paul-igas/igas-react-hooks";
import { sendConfirmEmail } from "../../../../store/consultas/usuarioestacion";

export function OptionTableRow(props) {
  const {
    data = {},
    onEdit = () => {},
    onDelete = () => {},
    onDetalle = () => {},
    ocultardetalle = () => {},
    onSelect = () => {},
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const sendEmailMsg = useModal(false);

  const handleClose = () => setAnchorEl(null);
  const { loading } = useSelector((state) => state.subscriptions);
  const dispatch = useDispatch();
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menu = usePopper(false);

  const handleSelect = (id) => {
    dispatch(setSuscripcionIdSelected(id));
  };

  const handleDetalles = () => {
    handleClose();
    handleSelect(data.id);
    onDetalle();
  };
  const handleEdit = () => {
    handleClose();
    handleSelect(data.id);
    onEdit();
  };

  const handleSendEmail = () => {
    handleClose();
    dispatch(sendConfirmEmail(data.idAdministrador))
      .then(sendEmailMsg.open)
      .catch(() => {});
  };

  // const handleDelete = () => {
  //   setAnchorEl();
  //   handleSelect(data.id);
  //   onDelete();
  // };

  return (
    <>
      {!ocultardetalle && (
        <Button size='small' disableElevation style={{ minWidth: 0 }} onClick={handleOpen}>
          <MoreVert fontSize='small' color='action' />
        </Button>
      )}
      {ocultardetalle && (
        <Button onClick={handleOpen} endIcon={<ExpandMore />}>
          Opciones
        </Button>
      )}

      {!loading.getId && (
        <>
          {Boolean(anchorEl) && (
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ zIndex: theme.zIndex.modal + 10 }}
            >
              {!ocultardetalle && (
                <MenuItem dense onClick={handleDetalles}>
                  <ListItemIcon>
                    <Pageview fontSize='small' />
                  </ListItemIcon>
                  <Typography variant='inherit'>Ver Detalle</Typography>
                </MenuItem>
              )}

              <MenuItem dense onClick={handleEdit}>
                <ListItemIcon>
                  <Edit fontSize='small' />
                </ListItemIcon>
                <Typography variant='inherit'>Editar</Typography>
              </MenuItem>

              {!data.administradorConfirmado && (
                <MenuItem onClick={handleSendEmail}>
                  <ListItemIcon>
                    <Email fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Enviar confirmación' />
                </MenuItem>
              )}

              {/* <MenuItem dense onClick={handleDelete}>
                <ListItemIcon>
                  <Backspace fontSize='small' />
                </ListItemIcon>
                <Typography variant='inherit'>Eliminar</Typography>
              </MenuItem> */}
            </Menu>
          )}
        </>
      )}
      <SuccessMessage
        open={sendEmailMsg.isShowing}
        onClose={sendEmailMsg.close}
        text='Se envió correo correctamente.'
      />
    </>
  );
}
export default OptionTableRow;
