import { Grid, InputAdornment, MenuItem, Tooltip } from "@mui/material";
import { IGasMultiSelectField, IGasSelectField, IGasTextField } from "@paul-igas/igas-react-components";
import { Info } from "@mui/icons-material";

export const DatosSucursal = ({
  tiposCaracter,
  clavesInstalacion,
  permisos,
  errors,
  isPermisionario,
  isContratoOrAsignacion,
  setIsPermisionario,
  setIsContratoOrAsignacion,
  idHidrocarburosList,
}) => {
  const handleChangeField = (e) => {
    if (e.target.value === 1 || e.target.value === 2) {
      setIsContratoOrAsignacion(true);
    } else {
      setIsContratoOrAsignacion(false);
    }

    if (e.target.value === 3) {
      setIsPermisionario(true);
    } else {
      setIsPermisionario(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <IGasTextField label='No. Estación' name='NoEs' maxLength={10}/>
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasMultiSelectField label='Id. Hidrocarburos' name='IdentificacionHidrocarburos' multiple defaultValue={[]}>
          {idHidrocarburosList.map((idHidrocarburo) => (
            <MenuItem key={idHidrocarburo} value={idHidrocarburo.text}>
              {idHidrocarburo.text}
            </MenuItem>
          ))}
        </IGasMultiSelectField>
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasSelectField
          label={
            <span>
              Caracter Efectos Regulatorios*
              <Tooltip
                disableFocusListener
                enterTouchDelay={1}
                leaveTouchDelay={5000}
                title='Caracter con el que actúa para efectos regulatorios.'
              >
                <Info color='primary' />
              </Tooltip>
            </span>
          }
          name='IdTipoCaracter'
          defaultValue='0'
          onChange={handleChangeField}
          error={errors.IdTipoCaracter}
        >
          <MenuItem value='0' disabled>
            <em>Seleccione Caracter de Efectos Regulatorios</em>
          </MenuItem>
          {tiposCaracter.map((i) => (
            <MenuItem
              key={i.idTipoCaracter}
              value={i.idTipoCaracter}
              style={{ maxWidth: "400px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "normal" }}
            >
              {i.valor}
            </MenuItem>
          ))}
        </IGasSelectField>
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasSelectField
          label={
            <span>
              Clave de Instalación*
              <Tooltip
                disableFocusListener
                enterTouchDelay={1}
                leaveTouchDelay={5000}
                title='La clave asignada deberá abarcar la instalación donde
                 se lleva a cabo la actividad de acuerdo al contrato, asignación o permiso.'
              >
                <Info color='primary' />
              </Tooltip>
            </span>
          }
          name='IdClaveInstalacion'
          defaultValue='0'
          error={errors.IdClaveInstalacion}
        >
          <MenuItem value='0' disabled>
            <em>Seleccione tipo de clave</em>
          </MenuItem>
          {clavesInstalacion.map((i) => (
            <MenuItem
              key={i.idClaveInstalacion}
              value={i.idClaveInstalacion}
              style={{ maxWidth: "400px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "normal" }}
            >
              {i.descripcion}
            </MenuItem>
          ))}
        </IGasSelectField>
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasTextField
          label='Número Contrato o Asignación'
          name='NumContratoOAsignacion'
          maxLength={30}
          error={errors?.NumContratoOAsignacion}
          disabled={!isContratoOrAsignacion}
          inputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Tooltip
                  disableFocusListener
                  enterTouchDelay={1}
                  leaveTouchDelay={5000}
                  title='Número de asignación otorgada por la Secretaría de Energía o el
                  número de contrato celebrado con la Comisión Nacional de Hidrocarburos.'
                >
                  <Info color='primary' />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasTextField
          label='Descripción de Instalación*'
          name='Descripcion'
          maxLength={100}
          error={errors.Descripcion}
          inputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Tooltip
                  disableFocusListener
                  enterTouchDelay={1}
                  leaveTouchDelay={5000}
                  title='Debe describir lo mejor posible el lugar donde se lleva a cabo la actividad y se instalaron sistemas de medición 
                para controles volumétricos, ej. Terminal de almacenamiento de gasolina con octanaje menor a 91, capacidad 200,000 litros.'
                >
                  <Info color='primary' />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasSelectField
          label='Modalidad Permiso'
          name='IdClavePermiso'
          defaultValue='0'
          disabled={!isPermisionario}
          error={errors?.IdClavePermiso}
        >
          <MenuItem value='0' disabled>
            <em>Seleccione tipo de clave</em>
          </MenuItem>
          {permisos.map((i) => (
            <MenuItem
              key={i.idClavePermiso}
              value={i.idClavePermiso}
              style={{ maxWidth: "400px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "normal" }}
            >
              {i.descripcionPermiso}
            </MenuItem>
          ))}
        </IGasSelectField>
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasTextField
          label='Permiso CRE*'
          name='NoPermisoCRE'
          maxLength={24}
          error={errors.NoPermisoCRE}
          inputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Tooltip
                  disableFocusListener
                  enterTouchDelay={1}
                  leaveTouchDelay={5000}
                  title='Permiso asignado por la CRE, ej. PL/56987/EXP/ES/5689.'
                >
                  <Info color='primary' />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <IGasTextField
          label='Nombre Comercial*'
          name='NombreComercial'
          maxLength={100}
          error={errors.NombreComercial}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <IGasTextField label='Domicilio Sucursal' name='DomicilioSucursal' maxLength={300} />
      </Grid>
    </Grid>
  );
};

export default DatosSucursal;
