import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export function VersionStep({ data }) {
    const { actualizaModeloVersion, modeloVersion, errorsSteps, actualizaErrores } = data
    const [errors, setErrors] = useState({});

    const handleChange = e => {
        validateChange(e);
        actualizaModeloVersion({
            ...modeloVersion,
            [e.target.name]: e.target.value
        });
    }

    const validateChange = (e) => {
        if (e.target.name === 'noVersion') {
            return validateNoVersion(e.target);
        }
        else if (e.target.name === 'producto') {
            return validateProducto(e.target);
        }
        else return;
    }

    const validateNoVersion = (data) => {
        const newErrors = { errorNoVersion: "" };
        if (data.value.length > 15) {
            newErrors.errorNoVersion = "No debe ser mayor a 15."
        } else if (data.value.length <= 0) {
            newErrors.errorNoVersion = "Numero Versión es requerido."
        }
        return changeListErrors(newErrors);
    }

    const validateProducto = (data) => {
        const newErrors = { producto: "" };
        if (data.value.length <= 0) {
            newErrors.producto = "Producto es requerido"
        }
        return changeListErrors(newErrors);
    }
    const changeListErrors = (newErrors) => {
        if (Object.keys(newErrors).length > 0) {
            setErrors(prevErrors => {
                const updatedErrors = { ...prevErrors };
                Object.keys(newErrors).forEach(key => {
                    updatedErrors[key] = newErrors[key];
                });
                return updatedErrors;
            });
            actualizaErrores({ ...errorsSteps, version: { ...errorsSteps.version, ...newErrors } });
            return !Object.values(newErrors).every((x) => x === "");
        }
    }
    const initialDatos = () => {
        setErrors(errorsSteps.version);
    }
    useEffect(initialDatos, [errorsSteps]);
    return (
        <>
            <Grid container md={4} rowSpacing={1} direction="column">
                <Grid item xs={12}>
                    <TextField
                        id="producto"
                        error={errors?.producto}
                        value={modeloVersion?.producto || ''}
                        helperText={errors?.producto}
                        label="Producto"
                        name='producto'
                        variant="standard"
                        inputProps={{ maxLength: 100 }}
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextField
                        id="noVersion"
                        error={errors?.errorNoVersion}
                        value={modeloVersion?.noVersion || ''}
                        helperText={errors?.errorNoVersion}
                        label="Numero Versión"
                        name='noVersion'
                        variant="standard"
                        inputProps={{ maxLength: 15 }}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </>
    );
}