import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Authorize, Anonymous } from "../layout/auth";
import { Forbidden, Loading, NotFound } from "../pages/auth";
import { useDispatch, useSelector } from "react-redux";
import { setBackdrop, setProfile, setState, STATES } from "../store/application";
import { getUser, isAuth, login } from "../api/authService";
import { validRoles } from "./helpers/user";

export default function AuthRoute() {
  const { routes, profile, state, backdrop } = useSelector((state) => state.application);
  const _path = useLocation();
  const dispatch = useDispatch();

  const initProfile = async (state) => {
    const user = await getUser();

    dispatch(setProfile(user?.profile));
    dispatch(setState(state));
  };

  const validAuth = async () => {
    const res = await isAuth();

    if (res === 200) return initProfile(STATES.AUTHORIZED);
    if (res === 403) return initProfile(STATES.FORBIDDEN);

    return await login();
  };

  const validRole = () => {
    try {
      //obtiene route
      const route = flatArray(routes).find((route) => route.route === _path.pathname);
      //validaRol
      return !route.roles || validRoles(profile.role, route.roles) ? <Outlet /> : <Forbidden />;
    } catch {
      return <NotFound />;
    }
  };

  useEffect(() => {
    validAuth();
  }, []);

  if (state === STATES.LOADING)
    return (
      <Anonymous>
        <Loading />
      </Anonymous>
    );
  if (state === STATES.FORBIDDEN)
    return (
      <Anonymous>
        <Forbidden />
      </Anonymous>
    );
  if (state === STATES.AUTHORIZED)
    return (
      <Authorize
        title='Matriz'
        routes={routes}
        profile={profile}
        backdrop={backdrop}
        setBackdrop={(value) => dispatch(setBackdrop(value))}
      >
        {validRole()}
      </Authorize>
    );
}

function flatArray(array) {
  return array.reduce((acc, val) => {
    acc.push(val);
    if (val.childrens && val.childrens.length > 0) acc.push.apply(acc, flatArray(val.childrens));

    return acc;
  }, []);
}
