import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import { GetModulos } from "../../api/services/entregables/modulos";

export const getmodulos = createAsyncThunk(
  "api/Modulos/",
  async (_, { rejectWithValue }) => {
    try {
      return await GetModulos();
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const initialState = {
  modulo: { id: 0 },
  modulos: [],
  error: {},
  loading: {
    getmodulos: false,
  },
};

const slice = createSlice({
  name: "modulos",
  initialState,
  reducers: {
    setModulo: (state, action) => {
      state.modulo = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getmodulos.pending, (state) => {
      state.loading.getmodulos = true;
    }),
      builder.addCase(getmodulos.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getmodulos = false;
        state.modulos = action.payload;
      }),
      builder.addCase(getmodulos.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getmodulos = false;
        state.modulos = [];
      });
  },
});

export default slice.reducer;

export const { clearErrors, setModulo } = slice.actions;
