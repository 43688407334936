import api from "../service";

const _controller = "entregables/Modulos";

export async function GetModulosDev() {
  const response = await api.get(`${_controller}/LastByModulo`);
  return response.data;
}

export default { GetModulosDev };
