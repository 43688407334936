import { Button, Grid, Typography } from "@mui/material";
import { ColumnPriority, DangerMessage, HeaderIGas, SimpleTable } from "@paul-igas/igas-react-components";
import { useEffect, useState } from "react";
import { useModal } from "@paul-igas/igas-react-hooks";
import HashAbreviado from "../hashAbreviado";
import IconoTipoTicket from "../inconoTipoVersion";

const TIPO_CASO = (value) => <IconoTipoTicket data={value} />;

const HASH_COMPLET = (value) => <HashAbreviado data={value} />;

const TABLA_ENTREGABLES = {
    columns: [
        { text: "Descripción", name: "descripcion",colPriority: ColumnPriority.High, },
        { text: "Hash", name: "hash", renderCell: HASH_COMPLET,colPriority: ColumnPriority.High, },
    ],
};
const TABLA_TICKET = {
    columns: [
        {
            text: "", name: "id", icon: true, renderCell: TIPO_CASO,colPriority: ColumnPriority.High,
        },
        { text: "Dev", name: "ticketDev",colPriority: ColumnPriority.Normal },
        { text: "Soporte", name: "ticketSoporte",colPriority: ColumnPriority.Normal },
        { text: "Descripción", name: "descripcion",colPriority: ColumnPriority.High },
        { text: "Hash", name: "hash",colPriority: ColumnPriority.Low, renderCell: HASH_COMPLET },
    ],
};

export function ResumenStep({ data }) {
    const { modeloVersion, modeloTickets, modeloEntregables, onSave, errorsSteps, actualizaErrores } = data
    const dangerMsg = useModal(false);
    const [error, setError] = useState({});
    const [canSave, setCanSave] = useState(false);

    function noEstaVacio(valor) {
        return valor !== null && valor !== undefined && valor !== '';
    }
    const buttonGuardarVersion = () => {
        for (let propiedad in errorsSteps) {
            for (let subPropiedad in errorsSteps[propiedad]) {

                if (noEstaVacio(errorsSteps[propiedad][subPropiedad])) {
                    setCanSave(true);
                } else {
                    setCanSave(false);
                }
            }
        }
    }

    const handleCloseError = () => {
        dangerMsg.close();
    };
    const initLista = () => {
        buttonGuardarVersion();
        setError(errorsSteps);
    }
    useEffect(() => {
        initLista();
    }, []);
    return (
        <>
            <Grid container style={{ marginTop: 6, marginBottom: 8 }}>
                <Grid item xs={12} >
                    <HeaderIGas title="Versión" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' color='textSecondary'>
                        <strong>Producto: </strong>
                        {modeloVersion?.producto}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {Boolean(error.version?.producto) && (
                        <Typography variant='caption' marginLeft={2} style={{ color: "red" }}>{`* ${error.version?.producto}`}</Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' color='textSecondary'>
                        <strong>Numero Versión: </strong>
                        {modeloVersion?.noVersion}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {
                        Boolean(error.version?.errorNoVersion)
                        && (
                            <Typography variant='caption' marginLeft={2} style={{ color: "red" }}>{`* ${error.version?.errorNoVersion}`}</Typography>
                        )}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <HeaderIGas title="Entregables" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <SimpleTable
                        table={TABLA_ENTREGABLES}
                        data={modeloEntregables}
                        showPagination={false}
                    />
                </Grid>
                {
                    Boolean(error.entregables?.listaEntregables) && (
                        <Grid item xs={12}>
                            <Typography variant='caption' marginLeft={2} style={{ color: "red" }}>{`*${error.entregables?.listaEntregables}`}</Typography>
                        </Grid>
                    )
                }
                <Grid item xs={12}>
                    <HeaderIGas title="Tickets" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <SimpleTable
                        table={TABLA_TICKET}
                        data={modeloTickets}
                        showPagination={false}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ marginBottom: 8 }}>
                <Grid item xs={12} md={2}>
                    <Button disabled={canSave} variant='contained' color='secondary' disableElevation onClick={onSave}>
                        Guardar Version
                    </Button>
                </Grid>
            </Grid>
            <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.version} />
            <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.entregables} />
        </>
    );
}

export default ResumenStep;
