import { ExitToApp, Menu } from "@mui/icons-material";
import { AppBar, Hidden, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import logo from "../../../../assets/img/logos/appMenu.png";
import { UseAuthContext, UseAuthTheme } from "../../../../context/auth";
import MenuUser from "./menuUser";

export default function AppBarTopMenu() {
  const theme = UseAuthTheme();
  const { leftMenu, title, profile, logoutDialog } = UseAuthContext();
  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <AppBar
        position='fixed'
        color='inherit'
        style={{ display: "flex" }}
        sx={{
          [theme.breakpoints.up("sm")]: {
            zIndex: theme.zIndex.drawer,
          },
          transition: theme.transitions.create(["margin", "widht"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Toolbar>
          {/* Botón para mostrar/ocultar el menú lateral */}
          <IconButton
            aria-label='open drawer'
            onClick={leftMenu.toggle}
            edge='start'
            style={{ marginRight: theme.spacing(2) }}
          >
            <Menu />
          </IconButton>

          <Hidden smDown>
            <img src={logo} alt='logo' style={{ marginRight: theme.spacing(2) }} />
          </Hidden>
          <Typography variant='h6' noWrap style={{ flexGrow: 1 }}>
            {title}
          </Typography>

          <Typography variant='h6' noWrap style={{ flexGrow: 1 }}></Typography>

          <Typography variant='subtitle2' noWrap>
            {profile.fullName}
          </Typography>

          {/* menu de usuario */}
          <MenuUser />

          {/* Opción para cierre de sesión */}
          <Tooltip title='Cerrar Sesión'>
            <IconButton onClick={logoutDialog.open} onMouseDown={handleMouseDown}>
              <ExitToApp />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </>
  );
}
