import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageViewIcon from "@mui/icons-material/Pageview";
import {
  clearErrors,
  setModulo,
  getmodulos,
} from "../../store/entregables/modulos";
import {
  Catalogo,
  ColumnAlign,
  ColumnPriority,
  DangerMessage,
  WarningMessage,
} from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useNavigate } from "react-router-dom";

const DETALLE = () => {
  return <PageViewIcon fontSize="small" style={{ color: "#757575" }} />;
};

const ID = (value) => {
  var hash = value.id.toString();
  return hash.substring(hash.length - 8);
};

const TABLE_DEFAULT = {
  columns: [
    {
      text: "Id",
      name: "id",
      colAlign: ColumnAlign.Left,
      colPriority: ColumnPriority.Normal,
      renderCell: ID,
    },
    {
      text: "Descripción",
      name: "descripcion",
      colAlign: ColumnAlign.Left,
      colPriority: ColumnPriority.High,
    },
    {
      name: "Opciones",
      linkDetail: true,
      colPriority: ColumnPriority.High,
      colAlign: ColumnAlign.Center,
      icon: true,
      renderCell: DETALLE,
    },
  ],
};

export function Modulos() {
  const dispatch = useDispatch();
  const { error, loading, modulos } = useSelector((state) => state.modulos);
  const navigate = useNavigate();
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const handleRefresh = () => {
    dispatch(getmodulos());
  };

  const handleSelectModulo = (id) => {
    const moduloSeleccionado = modulos.find(
      (modulo) => modulo.id === id
    );
    dispatch(setModulo(moduloSeleccionado));
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleNav = () => {
    navigate("/entregables/detallemodulos");
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo
        title="Entregables por Módulo"
        table={TABLE_DEFAULT}
        data={modulos}
        onDetail={handleNav}
        loading={loading.getmodulos}
        onRefresh={handleRefresh}
        onSelect={handleSelectModulo}
        showPagination={false}
      />
      <DangerMessage
        open={dangerMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
      <WarningMessage
        open={warningMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
    </>
  );
}