import { Button } from "@mui/material";
import { DangerButton } from "@paul-igas/igas-react-components";

const sxBackButton = {
  color: "black",
  backgroundColor: "gainsboro",
  "&:hover": {
    backgroundColor: "#B4B3B2",
  },
};

export function StepperManager(props) {
  const { disableBackButton, disableNextButton, onCancel, onReturn, onContinue } = props;
  return (
    <>
      <DangerButton variant='contained' style={{ marginRight: 8 }} size='small' disableElevation onClick={onCancel}>
        Cancelar
      </DangerButton>
      <Button
        variant='contained'
        style={{ marginRight: 8 }}
        sx={sxBackButton}
        size='small'
        disableElevation
        disabled={disableBackButton}
        onClick={onReturn}
      >
        Atras
      </Button>
      <Button
        variant='contained'
        size='small'
        disableElevation
        disabled={disableNextButton}
        onClick={onContinue}
        color='primary'
      >
        Siguiente
      </Button>
    </>
  );
}
