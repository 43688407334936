import { combineReducers, configureStore } from "@reduxjs/toolkit";
import application from "./application";
import subscriptions from "./catalogos/suscripciones";
import estaciones from "./consultas/estacion";
import licencias from "./consultas/licencias";
import panelEstaciones from "./consultas/panelEstaciones";
import facCloud from "./consultas/faccts/facCloud";
import facCloudBitacora from "./consultas/faccts/facCloudBitacora";
import versiones from "./entregables/versiones";
import modulos from "./entregables/modulos";
import modulosdetalle from "./entregables/modulosdetalle";
import entregables from "./entregables/entregablesDetalleVersion";
import tickets from "./entregables/ticketDetalleVersion";
import modulosDev from "./consultas/Modulos/modulosDev";
import ticketsDev from "./consultas/Tickets/ticketsDev";
import bitacoraReplicacion from "./consultas/bitacoraReplicacion";

const reducer = combineReducers({
  application,
  subscriptions,
  estaciones,
  licencias,
  panelEstaciones,
  facCloud,
  facCloudBitacora,
  versiones,
  entregables,
  modulosDev,
  tickets,
  modulos,
  modulosdetalle,
  ticketsDev,
  bitacoraReplicacion,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
