import React from "react";
import { FlatButton } from "@paul-igas/igas-react-components";
import { useDispatch } from "react-redux";
import {getTokenEntregables} from "../../store/entregables/entregablesDetalleVersion";
import { Tooltip } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

export const OptionEntregables = ({ data }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(getTokenEntregables(data.id))
  .then(({ payload }) => {
    window.open(payload, '_blank');
  });
  };
  
  return (
    <>
      <Tooltip title='Descargar Entregable' arrow>
        <FlatButton onClick={handleClick}>
            <DownloadIcon fontSize="small" style={{ color: "#757575" }} />
        </FlatButton>
      </Tooltip>
    </>
  );
};

export default OptionEntregables;
