import api from "../service";
export async function GetStations(id) {
  const params = { IdSuscripcion: id };
  const res = await api.get("estacion/Servicios/selectEstaciones", { params });
  return res.data;
}
export async function GetAllStations(filtro) {
  const params = { filtro: filtro.value.toString() };
  const res = await api.get("estacion/Servicios/selectAll", { params });
  return res.data;
}
export async function UpdateStations(params) {
  const estaciones = { idEstacion: params.stations };
  const res = await api.post(`suscripcion/servicios/insertDetalle?id=${params.id}`, params.stations);
  return res.data;
}

export async function InsertStation(params) {
  const res = await api.post("estacion/Servicios", params);
  return res.data;
}

export async function GetPermisos() {
  const res = await api.get("estacion/Servicios/ListarPermiso");
  return res.data;
}

export async function GetClaveInstalacionList() {
  const res = await api.get("estacion/Servicios/ListarClaveInstalacion");
  return res.data;
}

export async function GetTipoCaracterList() {
  const res = await api.get("estacion/Servicios/ListarTipoCaracter");
  return res.data;
}

export async function GetClaveUnidadList() {
  const res = await api.get("estacion/Servicios/ListarClaveUnidad");
  return res.data;
}

export async function GetTimeZones() {
  const res = await api.get("estacion/Servicios/selectTimeZones");
  return res.data;
}

export async function GetIdHidrocarburos() {
  const res = await api.get("estacion/Servicios/selectIdHidrocarburos");
  return res.data;
}

export default {
  GetStations,
  GetAllStations,
  UpdateStations,
  GetPermisos,
  GetClaveInstalacionList,
  GetClaveUnidadList,
  GetTimeZones,
  InsertStation,
  GetIdHidrocarburos,
};
