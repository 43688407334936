import { ExitToApp } from "@mui/icons-material";
import {
  Avatar,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { UseAuthContext, UseAuthTheme } from "../../../../context/auth";
import { useModal } from "@paul-igas/igas-react-hooks";

export default function MenuUser() {
  const theme = UseAuthTheme();
  const { profile, logoutDialog } = UseAuthContext();
  const menu = useModal(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    menu.toggle();
  }

  if (!profile) return null;

  return (
    <>
      <Tooltip title='Perfil de Usuario'>
        <IconButton onClick={handleClick} color='inherit' onMouseDown={handleMouseDown}>
          <Avatar
            style={{
              backgroundColor: theme.palette.primary.main,
              width: theme.spacing(3),
              height: theme.spacing(3),
            }}
          >
            {profile.fullName.charAt(0).toUpperCase()}
          </Avatar>
        </IconButton>
      </Tooltip>
      {menu.isShowing && (
        <Popper
          open={menu.isShowing}
          anchorEl={anchorEl}
          placement={"bottom-end"}
          sx={{
            zIndex: theme.zIndex.appBar + 1,
            [theme.breakpoints.up("sm")]: {
              zIndex: theme.zIndex.drawer + 2,
            },
          }}
        >
          <ClickAwayListener onClickAway={menu.close}>
            <Paper>
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItem>
                  <ListItemText>
                    <Typography variant='body1' display='block' noWrap>
                      {profile.fullName}
                    </Typography>
                    <Typography variant='body2' display='block' noWrap>
                      {profile.email}
                    </Typography>
                    <Typography variant='caption' display='block' noWrap={true}>
                      {Array.isArray(profile.role) ? profile.role.join(",") : profile.role}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
              <Divider />
              <List dense>
                <ListItem button onClick={logoutDialog.open}>
                  <ListItemIcon>
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText primary='Cerrar Sesión' />
                </ListItem>
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
}
