import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { DetailAlign, DetailInfo } from "@paul-igas/igas-react-components";
import React from "react";
import { useSelector } from "react-redux";

export const DetailDialog = ({ open, onClose }) => {
  const { registro } = useSelector((state) => state.facCloudBitacora);
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={"sm"}
        scroll={"paper"}
      >
        <DialogTitle onClose={onClose}>Detalle de Evento</DialogTitle>
        <DialogContent dividers={false}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <DetailInfo title="" description={registro} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" disableElevation>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DetailDialog;
