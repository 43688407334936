import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "@paul-igas/igas-react-hooks";
import {
    clearErrors,
    getVersionId,
    insertVersion,
    putVersion,
} from "../../store/entregables/versiones";
import {
    DangerMessage,
    WarningMessage,
    LoadingPanel,
} from "@paul-igas/igas-react-components";
import {
    StepperManager
} from "../../components/entregables";
import {
    CancelDialog
} from "../../components/entregables/dialogs";
import { Grid, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useWidth } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { VersionStep } from "../../components/entregables/steps/versionStep";
import { EntregablesStep } from "../../components/entregables/steps/entregablesStep";
import { ResumenStep, TicketsStep } from "../../components/entregables/steps";
import { hasDispatchError } from "../../api/services/service";
import { Forbidden } from "../auth/forbidden";



const steps = ["Versión", "Entregables", "Tickets", "Resumen"];
const stepsMin = ["V.", "Ent.", "Tkt.", "Resumen"];

const useAgregarVersion = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const screen = useWidth();

    const [step, setStep] = useState(0);
    const [disableBtns, setDisableBtns] = useState(true);
    const { loading, version } = useSelector((state) => state.versiones);

    const [modeloVersion, setModeloVersion] = useState({});
    const [modeloEntregables, setModeloEntregables] = useState([]);
    const [modeloTickets, setModeloTickets] = useState([]);

    const [saving, setSaving] = useState(false);

    const [errorsSteps, setErrorsSteps] = useState({
        version: {},
        entregables: {},
        tickets: {},
    });

    const validaBotonSiguiente = () => {
        if (step === 0 && (errorsSteps.version.producto?.trim().length > 0 || errorsSteps.version.errorNoVersion?.trim().length > 0) || Object.keys(modeloVersion).length <= 1) {
            return true;
        }
        if (step === 1 && Object.keys(modeloEntregables).length <= 0)
            return true;
        if (step === 2 && errorsSteps.entregables.listaEntregables?.trim().length > 0 && Object.keys(modeloEntregables).length > 0) {
            const updatedErrors = { ...errorsSteps.entregables };
            updatedErrors.listaEntregables = "";
            setErrorsSteps({ ...errorsSteps, entregables: { ...errorsSteps.entregables, ...updatedErrors } });
            return false;
        }
        if (step === 3) return true;

        return false;
    };
    const siguienteStep = () => {
        setStep((step) => step + 1);
    };
    const goList = () => {
        navigate("/entregables/versiones");
    };

    const onSave = async () => {
        dispatch(
            insertVersion({ ...modeloVersion, Entregables: modeloEntregables, Tickets: modeloTickets })
        )
            .then(hasDispatchError)
            .then(goList)
            .catch(() => { });
    };
    useEffect(() => {
        setDisableBtns(false);
        loading.insert ? setSaving(true) : setSaving(false);
    }, [loading]);
    return {
        step,
        saving,
        disableBtns,
        modeloVersion,
        modeloEntregables,
        modeloTickets,
        screen,
        errorsSteps,
        validaBotonSiguiente,
        siguienteStep,
        anteriorStep: () => setStep((step) => step - 1),
        desactivaStepBtn: () => setDisableBtns(true),
        activaStepBtn: () => setDisableBtns(false),
        actualizaModeloVersion: (mv) => { setModeloVersion(mv) },
        actualizaModeloEntregables: (me) => { setModeloEntregables(me) },
        actualizaModeloTickets: (mt) => { setModeloTickets(mt) },
        actualizaErrores: (err) => { setErrorsSteps(err) },
        onSave,
    };
}

export function AgregarVersionPage() {
    const data = useAgregarVersion();
    const dispatch = useDispatch();

    const { error } = useSelector((state) => state.versiones);

    const { profile } = useSelector((state) => state.application);

    const warningMsg = useModal(false);
    const dangerMsg = useModal(false);
    const cancelarDlg = useModal(false);



    const handleError = () => {
        if (Boolean(error?.status)) {
            error.status === 409 && warningMsg.open();
            error.status === 500 && dangerMsg.open();
        }
    };

    const handleCloseError = () => {
        dispatch(clearErrors());
        dangerMsg.close();
        warningMsg.close();
    };

    useEffect(handleError, [error]);

    if (!profile.AdministraActualizaciones === true)
        return (<Forbidden />)

    return (
        <>
            {!data.saving && (
                <Grid container justifyContent='space-between'>
                    <Grid md={6} sm={5} xs={4} item>
                        <Typography variant={(data.screen === "xs" || data.screen === "sm" ? 'h6' : 'h5')} paragraph>
                            {"Agregar Versión"}
                        </Typography>
                    </Grid>
                    <Grid item md={6} sm={5} xs={8} textAlign='end'>
                        <StepperManager
                            disableBackButton={data.disableBtns || data.step === 0}
                            disableNextButton={data.validaBotonSiguiente()}
                            onCancel={cancelarDlg.open}
                            onReturn={data.anteriorStep}
                            onContinue={data.siguienteStep}
                        />
                    </Grid>
                </Grid>
            )}

            <CancelDialog open={cancelarDlg.isShowing} onClose={cancelarDlg.close} />
            {
                data.saving ? (
                    <LoadingPanel title='Guardando Versión...' size={100} />
                ) : (
                    <>
                        <Paper style={{ marginTop: 6, marginBottom: 8 }}>
                            <Stepper activeStep={data.step} style={{ padding: 16 }} alternativeLabel={true}>
                                {(data.screen === "xs" || data.screen === "sm" ? stepsMin : steps).map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Paper>
                        {data.step === 0 && <VersionStep data={data} />}
                        {data.step === 1 && <EntregablesStep data={data} />}
                        {data.step === 2 && <TicketsStep data={data} />}
                        {data.step === 3 && <ResumenStep data={data} />}
                    </>
                )
            }
            <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
            <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
        </>
    );
}

export default AgregarVersionPage;

