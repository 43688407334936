import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetPanelEstaciones } from "../../api/services/consultas/panelEstaciones";
import { hasErrorRedux } from "../../api/services/service";

export const getPanelEstaciones = createAsyncThunk("servicios/getPanelEstaciones", async (_, { rejectWithValue }) => {
  try {
    return await GetPanelEstaciones();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const initialState = {
  panelEstaciones: [],
  error: {},
  loading: {
    getPanelEstaciones: false,
  },
};

const slice = createSlice({
  name: "panelEstaciones",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getPanelEstaciones.pending, (state) => {
      state.loading.getPanelEstaciones = true;
    }),
      builder.addCase(getPanelEstaciones.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getPanelEstaciones = false;
        state.panelEstaciones = action.payload;
      }),
      builder.addCase(getPanelEstaciones.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getPanelEstaciones = false;
        state.panelEstaciones = [];
      });
  },
});

export default slice.reducer;

export const { clearErrors } = slice.actions;
