import api from "../service";
import qs from "qs";
export async function InsertUserAdmin(params) {
    const params_env = { email: params.email, idSuscripcion: params.id }
    const res = await api.post('usuarioestacion/servicios/insertAdmin?' + qs.stringify(params_env));
  return res.data;
}

export async function SendConfirmEmail(id) {
  const params = { id: id };
  const res = await api.get(`usuarioestacion/servicios/EnviarCorreo`, { params });
  return res.data;
}

export async function GetAll(idSuscripcion) {
  let params = {
    suscription: idSuscripcion,
    activo: true,
    confirmado: true,
    role: "Administrador",
  };
  const res = await api.get("usuarioEstacion/servicios/selectUsuarios", { params });
  return res.data;
}

export default {
  InsertUserAdmin,
  SendConfirmEmail,
  GetAll,
};
