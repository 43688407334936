import { AddCircle, Edit, RemoveCircle, Save } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";

export function BotonAction(props) {
    const {
        title,
        onClick = () => { },
        action
    } = props;
    const iconoAction = action === 0 ? <AddCircle /> : action === 1 ? <RemoveCircle /> : action === 2 ? <Edit /> : action === 3 ? <Save />: null;

    return (
        <>
            <Tooltip title={title}>
                <Button
                    style={{ minWidth: 0 }}
                    size='small'
                    disableElevation
                    onClick={onClick}
                    color={action === 1 ? 'error' : 'primary'}
                >
                    {iconoAction}
                </Button>
            </Tooltip>
        </>
    );
}