import { useDispatch, useSelector } from "react-redux";
import { Catalogo, ColumnAlign, ColumnPriority } from "@paul-igas/igas-react-components";
import { getPanelEstaciones } from "../../store/consultas/panelEstaciones";
import { OptionFechaHoraReplicacion, OptionLicencia } from "../../components/consultas/panelEstaciones";

const CELL_LICENCIA = (value) => <OptionLicencia data={value} />;
const CELL_REPLICACION = (value) => <OptionFechaHoraReplicacion data={value} />;

const TABLE_DEFAULT = {
  columns: [
    { text: "Permiso CRE", name: "cre", colAlign: ColumnAlign.Left, colPriority: ColumnPriority.Medium },
    {
      text: "Nombre Comercial",
      name: "nombre",
      colAlign: ColumnAlign.Left,
    },
    {
      text: "Razón Social",
      name: "razonSocial",
      colPriority: ColumnPriority.Low,
      colAlign: ColumnAlign.Left,
    },
    {
      text: "Licencia",
      name: "licenciaEstacion",
      colAlign: ColumnAlign.Center,
      icon: true,
      renderCell: CELL_LICENCIA,
    },
    {
      text: "Replicación",
      name: "fechaHoraReplicacion",
      colAlign: ColumnAlign.Center,
      icon: true,
      renderCell: CELL_REPLICACION,
    },
  ],
};

export const PanelEstaciones = () => {
  const dispatch = useDispatch();
  const { loading, panelEstaciones, error } = useSelector((state) => state.panelEstaciones);
  const handleRefresh = () => {
    dispatch(getPanelEstaciones());
  };

  return (
    <>
      <Catalogo
        title='Panel de Estaciones'
        table={TABLE_DEFAULT}
        data={panelEstaciones}
        loading={loading.getPanelEstaciones}
        onRefresh={handleRefresh}
      />
    </>
  );
};
