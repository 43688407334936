import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  setVersiones,
  getversiones,
} from "../../store/entregables/versiones";
import {
  Catalogo,
  ColumnAlign,
  ColumnPriority,
  ColumnType,
  DangerMessage,
  WarningMessage,
} from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useNavigate } from "react-router-dom";
import { OptionTableRowVersiones } from "../../components/entregables";

const TABLE_DEFAULT = {
  columns: [
    {
      text: "Producto",
      name: "producto",
      colPriority: ColumnPriority.High,

    },
    {
      text: "Versión",
      name: "noVersion",
      colAlign: ColumnAlign.Center,
      colPriority: ColumnPriority.High,
      linkDetail: true,
    },
    {
      text: "Publicación",
      name: "publicacion",
      colPriority: ColumnPriority.Normal,
      colAlign: ColumnAlign.Center,
      colType: ColumnType.DateTime,
    },
    {
      text: "Estado",
      name: "descripcionEstado",
      colPriority: ColumnPriority.Normal,
      colAlign: ColumnAlign.Left,
      colType: ColumnType.String,
  },
    { text: "", name: "action",colPriority: ColumnPriority.High, colAlign: ColumnAlign.Center, options: true, icon: true },
  ],
};

export function VersionesPage() {
  const dispatch = useDispatch();
  const { loading, versiones, error } = useSelector((state) => state.versiones);
  const { profile } = useSelector((state) => state.application);
  const navigate = useNavigate();
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const handleSelectVersion = (id) => {
    const versionSelectionada = versiones.find(
      (versiones) => versiones.id === id
    );
    dispatch(setVersiones(versionSelectionada));
  };

  const handleRefresh = () => {
    dispatch(getversiones());
  };

  const handleNav = () => {
    navigate("/entregables/detallesDeVersiones");
  };

  const handleInsert = () => {
    navigate("/entregables/agregarVersion");
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo
        title="Versiones"
        table={TABLE_DEFAULT}
        data={versiones}
        loading={loading.getversiones}
        onRefresh={handleRefresh}
        onDetail={handleNav}
        onSelect={handleSelectVersion}
        onInsert={Boolean(profile.AdministraActualizaciones) ? handleInsert : null}
        rowOptions={<OptionTableRowVersiones data={versiones} onRefreshDelete={handleRefresh} />}
      />
      <DangerMessage
        open={dangerMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
      <WarningMessage
        open={warningMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
    </>
  )
}
export default VersionesPage;
