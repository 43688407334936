import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { IGasForm, IGasRadioGroupField, IGasTextField } from "@paul-igas/igas-react-components";
import { useState } from "react";

export const RegistrarTicket = ({ open, onClose, data, postSubmit = () => {} }) => {
  const [errors, setErrors] = useState({});
  const { modeloTickets } = data;

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setErrors({});
    onClose();
  };

  const handleSave = (form) => {
    form.tipo = form.tipo === "cambio";
    if (validateSubmit(form)) {
      return;
    } else {
      const nuevoArreglo = [
        ...modeloTickets,
        { index: Math.max(...modeloTickets.map((ticket) => ticket.index || 0), 0) + 1, ...form },
      ];
      postSubmit(nuevoArreglo);
      handleClose();
    }
  };

  const validateSubmit = (dataFormulario) => {
    const newErrors = [];
    if (dataFormulario.ticketDev.length > 10) {
      newErrors.ticketDev = "El ticket Dev solo puede contener 10 caracteres.";
    }
    if (dataFormulario.ticketSoporte.length > 10) {
      newErrors.ticketSoporte = "El ticket Soporte solo puede contener 10 caracteres.";
    }
    if (dataFormulario.PathTicket.trim().length <= 0) {
      newErrors.PathTicket = "Path de Vtiger es requerido.";
    }
    setErrors(newErrors);
    return !Object.values(newErrors).every((x) => x === "");
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='md' fullWidth scroll='paper'>
        <DialogTitle>Registrar Ticket</DialogTitle>
        <DialogContent dividers={true}>
          <IGasForm id='registrar-ticket-dlg' onSubmit={handleSave}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <IGasTextField
                  error={errors?.ticketDev}
                  label='Ticket Dev'
                  name='ticketDev'
                  maxLength={10}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <IGasTextField
                  error={errors?.ticketSoporte}
                  label='Ticket Soporte'
                  name='ticketSoporte'
                  maxLength={10}
                />
              </Grid>
              <Grid item xs={12}>
                <IGasTextField label='Descripción' name='descripcion' defaultValue='' maxLength={200} />
              </Grid>
              <Grid item xs={12}>
                <IGasRadioGroupField
                  name='tipo'
                  asRow
                  label='Tipo'
                  defaultValue='fallo'
                  options={[
                    { label: "Fallo", value: "fallo" },
                    { label: "Cambio", value: "cambio" },
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <IGasTextField
                  error={errors?.PathTicket}
                  label='Path vtiger'
                  defaultValue=''
                  name='PathTicket'
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={12}>
                <IGasTextField label='Path informe (opcional)' name='pathInforme' defaultValue='' maxLength={100} />
              </Grid>
              <Grid item xs={12}>
                <IGasTextField label='Hash (opcional)' name='hash' maxLength={64} defaultValue='' />
              </Grid>
            </Grid>
          </IGasForm>
        </DialogContent>
        <DialogActions>
          <Button type='submit' color='secondary' form='registrar-ticket-dlg' disableElevation>
            Guardar
          </Button>
          <Button color='secondary' disableElevation onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
