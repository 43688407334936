import { CssBaseline, styled, ThemeProvider } from "@mui/material";
import Default from "../../../styles/theme/default";

const Root = styled('div')(() => ({
    height: '100vh',
    backgroundColor: '#eee'
}));

export function Anonymous(props) {
    const { children } = props;

    return (
        <ThemeProvider theme={Default}>
            <CssBaseline />
            <Root>
                <main>
                    {children}
                </main>
            </Root>
        </ThemeProvider>
    )
}