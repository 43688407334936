import { AddCircle } from "@mui/icons-material";
import { Button, Grid, Tooltip } from "@mui/material";
import { ColumnAlign, ColumnPriority, HeaderIGas, SimpleTable } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { RegistrarTicket } from "../dialogs/tickets/registraTickets";
import HashAbreviado from "../hashAbreviado";
import IconoTipoTicket from "../inconoTipoVersion";
import OptionTableRowDelete from "../optionTableRowSteps";

const CELL_HASH = (value) => <HashAbreviado data={value} />;

const TIPO_CASO = (value) => <IconoTipoTicket data={{ tipo: value.tipo }} />;


const FORM_DEFAULT = {
    columns: [
        {
            text: "", name: "tipo", icon: true,
            colPriority: ColumnPriority.High,
            renderCell: TIPO_CASO,
        },
        { text: "Dev", name: "ticketDev",colPriority: ColumnPriority.Normal },
        { text: "Soporte", name: "ticketSoporte",colPriority: ColumnPriority.Normal },
        { text: "Descripción", name: "descripcion" ,colPriority: ColumnPriority.High,},
        {
            text: "Hash", name: "hash",
            colPriority: ColumnPriority.Low,
            renderCell: CELL_HASH,
        },
        { text: "", name: "action", colPriority: ColumnPriority.High,colAlign: ColumnAlign.Center, options: true, icon: true },
    ],
};
export function TicketsStep({ data }) {
    const { actualizaModeloTickets, modeloTickets } = data
    const [loading, setLoading] = useState(false);
    const [listaTickets, setListaTickets] = useState([]);

    const nuevaTicketsDialog = useModal(false);


    const onSubmitTicket = (nuevoArreglo) => {
        setLoading(true);
        setListaTickets(nuevoArreglo);
        actualizaModeloTickets(nuevoArreglo);
        setLoading(false);
    };
    const initLista = () => {
        setListaTickets(modeloTickets);
    }
    useEffect(() => {
        initLista();
    }, []);
    return (
        <>
            <Grid container spacing={2} style={{ marginTop: 6, marginBottom: 8 }}>
                <Grid item md={6} xs={6}>
                    <HeaderIGas title="Tickets" />
                </Grid>
                <Grid item md={6} xs={6}>
                    <Tooltip title='Agregar tickets'>
                        <Button
                            style={{ minWidth: 0 }}
                            size='small'
                            disableElevation
                            onClick={nuevaTicketsDialog.open}
                        >
                            <AddCircle />
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: 6 }}>
                <Grid item xs={12} md={8}>
                    <SimpleTable
                        table={FORM_DEFAULT}
                        data={listaTickets}
                        showPagination={false}
                        loading={loading}
                        rowOptions={<OptionTableRowDelete lista={listaTickets} onSubmitDelete={onSubmitTicket} />}
                    />
                </Grid>
            </Grid>
            <RegistrarTicket open={nuevaTicketsDialog.isShowing} onClose={nuevaTicketsDialog.close} data={data} postSubmit={onSubmitTicket} />
        </>
    );
}
