export const validRoles = (roles1, roles2) => {
  let arrayRoles1 = Array.isArray(roles1) ? roles1 : [roles1];
  let arrayRoles2 = Array.isArray(roles2) ? roles2 : [roles2];

  //Se agrega el rol de Administrador para validar el acceso
  var tmp = arrayRoles2.slice();
  tmp.push("AdministradorIgas");

  arrayRoles2 = tmp;

  //valida que roles1 se encuentre en roles2
  return arrayRoles1.some((roles) => arrayRoles2.includes(roles));
};
