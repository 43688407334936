import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import { GetVersionesById } from "../../api/services/entregables/versiones";

export const getversionesbyid = createAsyncThunk(
  "api/versiones/",
  async (params, { rejectWithValue }) => {
    try {
      return await GetVersionesById(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const initialState = {
  version: { id: 0 },
  versiones: [],
  error: {},
  loading: {
    getversionesbyid: false,
  },
};

const slice = createSlice({
  name: "modulosdetalle",
  initialState,
  reducers: {
    setVersiones: (state, action) => {
      state.version = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getversionesbyid.pending, (state) => {
      state.loading.getversionesbyid = true;
    }),
      builder.addCase(getversionesbyid.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getversionesbyid = false;
        state.versiones = action.payload;
      }),
      builder.addCase(getversionesbyid.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getversionesbyid = false;
        state.versiones = [];
      });
  },
});

export default slice.reducer;

export const { clearErrors, setVersiones } = slice.actions;
