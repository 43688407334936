import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent, DialogTitle, Tab, Tabs, Paper, DialogActions, Button, Skeleton } from "@mui/material";
import { DangerMessage, IGasForm, SuccessMessage, WarningMessage } from "@paul-igas/igas-react-components";
import PropTypes from "prop-types";
import { useModal } from "@paul-igas/igas-react-hooks";
import { hasDispatchError } from "../../../api/services/service";
import { insert, clearErrors, initialState } from "../../../store/catalogos/suscripciones";
import {
  getClaveInstalacionList,
  getClaveUnidad,
  getIdHidrocarburos,
  getPermisos,
  getTimeZones,
  getTipoCaracterList,
} from "../../../store/consultas/estacion";
import { DatosEstacion, DatosSucursal } from "./components";
import { useWidth } from "../../../hooks/useWidth";

const estacionModel = {
  NoEs: "",
  IdentificacionHidrocarburos: "",
  IdTipoCaracter: "0",
  IdClaveInstalacion: "0",
  NumContratoOAsignacion: "",
  Descripcion: "",
  IdClavePermiso: "0",
  NoPermisoCRE: "",
  NombreComercial: "",
  DomicilioSucursal: "",
  RazonSocial: "",
  RFCRepresentanteLegal: "",
  RFC: "",
  RepresentanteLegal: "",
  ClaveIdentificacion: "",
  IdClaveUnidad: "0",
  DomicilioFiscal: "",
  UTCTimeZone: "",
  Email: "",
};

export const RegistrarEstacion = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { tiposCaracter, clavesUnidad, clavesInstalacion, permisos, timeZones, error, idHidrocarburosList, loading } =
    useSelector((state) => state.estaciones);
  const [errors, setErrors] = useState({});
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPermisionario, setIsPermisionario] = useState(false);
  const [isContratoOrAsignacion, setIsContratoOrAsignacion] = useState(false);
  const [sleeping, setSleeping] = useState(false);

  const screen = useWidth();

  const wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
  const successMsg = useModal(false);
  const { selectedPage, handleChangePage } = useTabsHandler(open);

  const loadingEffect =
    loading.tiposCaracter ||
    loading.clavesUnidad ||
    loading.clavesInstalacion ||
    loading.permisos ||
    loading.timeZones ||
    loading.idHidrocarburosList ||
    sleeping;

  function validarEmail(Email) {
    var patron = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return patron.test(Email);
  }

  function validarClaveIdentificacion(ClaveIdentificacion) {
    var patron = /^[1-9]\d{0,3}$/;
    return patron.test(ClaveIdentificacion);
  }

  function validarRFC(RFC) {
    var patron =
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    return patron.test(RFC);
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setErrors({});
    onClose();
  };

  const validaForm = (formData) => {
    const newErrors = [];
    newErrors.RazonSocial = formData.RazonSocial === "" ? "Razón Social es requerido." : "";

    if (!formData.RFC) {
      newErrors.RFC = "RFC es requerido";
    } else if (!validarRFC(formData.RFC)) {
      newErrors.RFC = "RFC no contiene una estructura válida.";
    } else if (formData.RFC.length === 12) {
      newErrors.RepresentanteLegal =
        formData.RepresentanteLegal === "" ? "Nombre Representante Legal es requerido." : "";
      newErrors.RFCRepresentanteLegal =
        formData.RFCRepresentanteLegal === "" ? "RFC Representante Legal es requerido." : "";
    } else {
      newErrors.RFC = "";
    }

    if (formData.RFC.length === 12) {
      newErrors.RFCRepresentanteLegal = !validarRFC(formData.RFCRepresentanteLegal)
        ? "RFC Representante Legal no contiene una estructura válida"
        : "";
    }
    if (!formData.ClaveIdentificacion) {
      newErrors.ClaveIdentificacion = "Clave Identificación es requerido";
    } else if (!validarClaveIdentificacion(formData.ClaveIdentificacion)) {
      newErrors.ClaveIdentificacion = "El valor de Clave Identificación ser entre 0 y 9999.";
    } else {
      newErrors.ClaveIdentificacion = "";
    }

    newErrors.DomicilioFiscal = formData.DomicilioFiscal === "" ? "Domicilio Fiscal es requerido." : "";
    newErrors.IdClaveUnidad = formData.IdClaveUnidad.toString() === "0" ? "Unidad de Medida es requerida." : "";

    newErrors.UTCTimeZone = formData.UTCTimeZone === "" ? "Zona Horaria es requerida." : "";
    if (!formData.Email) {
      newErrors.Email = "Correo Electrónico es requerido";
    } else if (!validarEmail(formData.Email)) {
      newErrors.Email = "Correo Electrónico no tiene un formato válido.";
    } else {
      newErrors.Email = "";
    }
    newErrors.IdTipoCaracter =
      formData.IdTipoCaracter.toString() === "0" ? "Caracter Efectos Regulatorios es requerido." : "";
    newErrors.IdClaveInstalacion =
      formData.IdClaveInstalacion.toString() === "0" ? "Clave de Instalación es requerida." : "";

    if ((formData.IdTipoCaracter === 1 || formData.IdTipoCaracter === 2) && !formData.NumContratoOAsignacion) {
      newErrors.NumContratoOAsignacion = "Número Contrato o Asignación es requerido.";
    } else if (formData.IdTipoCaracter === 3 && !formData.IdClavePermiso) {
      newErrors.IdClavePermiso = "Modalidad Permiso es requerido.";
    } else {
      newErrors.NumContratoOAsignacion = "";
      newErrors.IdClavePermiso = "";
    }

    if (
      (formData.IdTipoCaracter === 1 || formData.IdTipoCaracter === 2) &&
      formData.NumContratoOAsignacion.toString() === ""
    ) {
      newErrors.IdTipoCaracter = "Tipo Caracter de Efectos Regulatorios no coincide.";
      newErrors.NumContratoOAsignacion = "Número Contrato o Asignación no puede quedar vacío.";
    }

    if (formData.IdTipoCaracter === 3 && formData.IdClavePermiso === 0) {
      newErrors.IdTipoCaracter = "Tipo Caracter de Efectos Regulatorios no coincide.";
      newErrors.IdClavePermiso = "Seleccione Modalidad Permiso.";
    }

    newErrors.Descripcion = formData.Descripcion === "" ? "Descripción de Instalación es requerida." : "";
    newErrors.NoPermisoCRE = formData.NoPermisoCRE === "" ? "Permiso CRE es requerido." : "";
    newErrors.NombreComercial = formData.NombreComercial === "" ? "Nombre Comercial es requerido." : "";

    setErrors(newErrors);
    return Object.values(newErrors).every((x) => x === "");
  };

  const handleSubmit = (data) => {
    const opcionesCombustibleString = JSON.stringify(data?.IdentificacionHidrocarburos || []);

    const nuevoEstacionModel = { ...estacionModel };
    for (const key in estacionModel) {
      if (estacionModel.hasOwnProperty(key)) {
        nuevoEstacionModel[key] = data[key] ?? estacionModel[key];
      }
    }
    nuevoEstacionModel.IdentificacionHidrocarburos = opcionesCombustibleString;
    if (validaForm(nuevoEstacionModel)) {
      dispatch(insert({ ...nuevoEstacionModel }))
        .then(hasDispatchError)
        .then(successMsg.open)
        .then(sleep)
        .then(handleClose)
        .catch(() => {
          setSleeping(false);
        });
    }
  };

  const sleep = async () => {
    setSleeping(true);
    await wait(1500);
    setSleeping(false);
  };

  const Init = () => {
    if (open) {
      setErrors({});
      dispatch(getTipoCaracterList())
        .then(hasDispatchError)
        .catch(() => {});
      dispatch(getClaveInstalacionList())
        .then(hasDispatchError)
        .catch(() => {});
      dispatch(getPermisos())
        .then(hasDispatchError)
        .catch(() => {});
      dispatch(getClaveUnidad())
        .then(hasDispatchError)
        .catch(() => {});
      dispatch(getTimeZones())
        .then(hasDispatchError)
        .catch(() => {});
      dispatch(getIdHidrocarburos())
        .then(hasDispatchError)
        .catch(() => {});
    }
  };

  const handleError = () => {
    if (typeof error?.message === "object") {
      var enc = new TextDecoder("utf-8");
      setErrorMessage(enc.decode(error.message));
    } else {
      setErrorMessage(error?.message);
    }
    if (Boolean(error?.status)) {
      error.status <= 409 && warningMsg.open();
      error.status >= 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(Init, [open]);
  useEffect(handleError, [error]);

  return (
    <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='md' fullWidth scroll='paper'>
      <DialogTitle>Registrar estación</DialogTitle>
      {loadingEffect && <Skeleton animation='wave' />}
      {!loadingEffect && (
        <DialogContent>
          <Tabs
            variant='scrollable'
            value={selectedPage}
            onChange={handleChangePage}
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            <Tab
              label={screen === "xs" || screen === "sm" ? "Estación" : "Datos de la Estación"}
              {...a11yProps(0)}
              sx={{
                ["&.Mui-selected"]: {
                  backgroundColor: "whitesmoke",
                },
              }}
            />
            <Tab
              label={screen === "xs" || screen === "sm" ? "Sucursal" : "Datos de la Sucursal"}
              {...a11yProps(1)}
              sx={{ ["&.Mui-selected"]: { backgroundColor: "whitesmoke" } }}
            />
          </Tabs>
          <IGasForm id='registrar-estacion-dlg' onSubmit={handleSubmit}>
            <TabPanel value={selectedPage} index={0}>
              <DatosEstacion clavesUnidad={clavesUnidad} errors={errors} timeZones={timeZones} open={open} />
            </TabPanel>
            <TabPanel value={selectedPage} index={1}>
              <DatosSucursal
                tiposCaracter={tiposCaracter}
                clavesInstalacion={clavesInstalacion}
                clavesUnidad={clavesUnidad}
                permisos={permisos}
                errors={errors}
                isContratoOrAsignacion={isContratoOrAsignacion}
                setIsContratoOrAsignacion={setIsContratoOrAsignacion}
                isPermisionario={isPermisionario}
                setIsPermisionario={setIsPermisionario}
                idHidrocarburosList={idHidrocarburosList}
              />
            </TabPanel>
          </IGasForm>
        </DialogContent>
      )}
      <DialogActions>
        <Button color='secondary' disableElevation disabled={loading.insert} onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          type='submit'
          color='secondary'
          form='registrar-estacion-dlg'
          disableElevation
          disabled={loading.insert}
        >
          Guardar
        </Button>
      </DialogActions>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se guardó correctamente.' />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={errorMessage} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={errorMessage} />
    </Dialog>
  );
};

const useTabsHandler = (open) => {
  const [selectedPage, setSelectedPage] = useState(0);

  const handleChange = (event, newPage) => {
    setSelectedPage(newPage);
  };

  const initPage = () => {
    if (open === true) setSelectedPage(0);
  };

  useEffect(initPage, [open]);

  return {
    selectedPage,
    handleChangePage: handleChange,
  };
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Paper sx={{ p: 2, backgroundColor: "whitesmoke", minHeight: "310px" }}>{children}</Paper>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default RegistrarEstacion;
