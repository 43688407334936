import React from "react";
import {Tooltip } from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BugReportIcon from '@mui/icons-material/BugReport';


export const IconoTipoTicket = ({ data = { tipo: false } }) => {
    if (data.tipo)
        return <Tooltip title='Cambio' arrow><CheckBoxIcon fontSize="small" style={{ color: "#757575" }} /></Tooltip>;
    else
        return <Tooltip title='Fallo' arrow><BugReportIcon fontSize="small" style={{ color: "#757575" }} /></Tooltip>;
};

export default IconoTipoTicket;
