import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import OptionEntregables from '../optionEntregables';
import HashAbreviado from '../hashAbreviado';
import { useWidth } from '../../../hooks/useWidth';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';
import { deleteEntregables } from '../../../store/entregables/entregablesDetalleVersion';
import { hasDispatchError } from '../../../api/services/service';
import { BotonAction } from './botonAction';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'descripcion', label: 'Descripción' },
    { id: 'hash', label: 'Hash' },
    { id: 'descarga', label: 'Descarga' },
    { id: 'action', label: '' },
];


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, permissionModify } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const screen = useWidth();
    return (
        <TableHead>
            <TableRow>
                <TableCell
                    padding="checkbox"
                    sx={{
                        fontWeight: "bold",
                        color: "black",
                        backgroundColor: "whitesmoke",
                        width: 'auto',
                    }}
                >
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => {
                    if (!permissionModify && headCell.id === 'action') {
                        return null;
                    }
                    if (screen === "xs" && headCell.id === 'hash') {
                        return null;
                    }
                    return (
                        <TableCell
                            key={headCell.id}
                            align={headCell.id !== 'descarga' ? 'left' : 'center'}
                            sx={{
                                fontWeight: "bold",
                                color: "black",
                                backgroundColor: "whitesmoke",
                                width: 'auto',
                            }}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export function TableEntregables(props) {

    const {
        data,
        onSelect = () => { },
        loading,
        permissionModify,
        onDelete
    } = props;

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('descripcion');
    const [selected, setSelected] = useState([]);
    const [rows, setRowsValue] = useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            const allCheckedItems = {};
            newSelected.forEach(id => {
                allCheckedItems[id] = true;
            });
            setSelected(newSelected);
            onSelect(allCheckedItems);
            return;
        }
        setSelected([]);
        onSelect({});
    };

    const handleClick = (event, id, isItemSelected) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        onSelect({ [id]: !isItemSelected });
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const visibleRows = React.useMemo(
        () => stableSort(rows, getComparator(order, orderBy)),
        [order, orderBy, rows]
    );


    const initLista = () => {
        if (data) {
            const newRows = data.map(item => ({
                id: item.id || '',
                descripcion: item.moduloDescripcion || '',
                hash: item.hash || '',
                descarga: item.pathEntregables || ''
            }));
            setRowsValue(newRows);
        }
    };

    useEffect(() => {
        initLista();
    }, [data]);

    const screen = useWidth();

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 1 }}>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            permissionModify={permissionModify}
                        />
                        <TableBody>
                            {loading ? (
                                <TableRow key={0}>
                                    <TableCell padding="checkbox">
                                        <Skeleton variant="rounded" width={40} height={40} />
                                    </TableCell>
                                    <TableCell >
                                        <Skeleton variant="text" />
                                    </TableCell>
                                    {screen !== "xs" && (
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        <Skeleton variant="text" />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell
                                                padding="checkbox"
                                                onClick={(event) => handleClick(event, row.id, isItemSelected)}
                                                selected={isItemSelected}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                onClick={(event) => handleClick(event, row.id, isItemSelected)}
                                                sx={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {row.descripcion}
                                            </TableCell >
                                            {(screen !== "xs") && (
                                                <TableCell align="left">
                                                    <HashAbreviado data={{ hash: row.hash }} />
                                                </TableCell>
                                            )}
                                            <TableCell align="center">
                                                <OptionEntregables data={row} />
                                            </TableCell>
                                            {(permissionModify && visibleRows.length > 1) && (
                                                <TableCell align="center">
                                                    <BotonAction
                                                        title={"Eliminar"}
                                                        onClick={() => onDelete(row.id)}
                                                        action={1}
                                                    />
                                                </TableCell>

                                            )}
                                        </TableRow>)

                                }))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

TableEntregables.propTypes = {
    data: PropTypes.array.isRequired,
};
