import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  clearErrors,
  setEstacion,
  getfaccloud,
} from "../../../store/consultas/faccts/facCloud";
import {
  Catalogo,
  ColumnAlign,
  ColumnPriority,
  ColumnType,
  DangerMessage,
  WarningMessage,
} from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ESTADO = (value) => {
  var horaLimite = new Date();
  var ultimoPingSb = new Date(value.ultimoPingSb);
  horaLimite.setHours(horaLimite.getHours() - 6);
  if (ultimoPingSb >= horaLimite) {
    return (
      <Tooltip title="Activo" arrow>
        <FiberManualRecordIcon fontSize="small" style={{ color: "#00cc00" }} />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Inactivo" arrow>
        <FiberManualRecordIcon fontSize="small" style={{ color: "#FF0000" }} />
      </Tooltip>
    );
  }
};

const FECHA = (value) => {
  if (value.ultimoPingSb != null) {
    var fechaString = value.ultimoPingSb;
    var fecha = new Date(fechaString);
    var anio = fecha.getFullYear();
    var mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    var dia = fecha.getDate().toString().padStart(2, "0");
    var hora = fecha.getHours().toString().padStart(2, "0");
    var minutos = fecha.getMinutes().toString().padStart(2, "0");
    var segundos = fecha.getSeconds().toString().padStart(2, "0");
    var fechaFormateada = `${dia}/${mes}/${anio} ${hora}:${minutos}:${segundos}`;
    return fechaFormateada;
  } else {
    return "N/A";
  }
};

const TABLE_DEFAULT = {
  columns: [
    {
      text: "Clave Cloud",
      name: "id",
      colAlign: ColumnAlign.Center,
      colType: ColumnType.DataKey,
      colPriority: ColumnPriority.High,
      digitos: 4,
    },
    {
      text: "Permiso CRE",
      name: "claveCre",
      colAlign: ColumnAlign.Left,
      colPriority: ColumnPriority.High,
      linkDetail: true,
    },
    {
      text: "Nombre",
      name: "nombreComercial",
      colAlign: ColumnAlign.Left,
    },
    {
      text: "Ultima Prueba Conexión",
      name: "ultimoPingSb",
      colAlign: ColumnAlign.Left,
      renderCell: FECHA,
    },
    {
      text: "Estado",
      name: "activo",
      colAlign: ColumnAlign.Center,
      colPriority: ColumnPriority.High,
      icon: true,
      renderCell: ESTADO,
    },
  ],
};

export function FacCloud() {
  const dispatch = useDispatch();
  const { loading, estaciones, error } = useSelector((state) => state.facCloud);
  const navigate = useNavigate();
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const handleRefresh = () => {
    dispatch(getfaccloud());
  };

  const handleSelectEstacion = (id) => {
    const estacionSeleccionada = estaciones.find(
      (estacion) => estacion.id === id
    );
    dispatch(setEstacion(estacionSeleccionada));
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleNav = () => {
    navigate("/consultas/faccts/faccloudbitacora");
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo
        title="Facturación Cloud"
        table={TABLE_DEFAULT}
        data={estaciones}
        onDetail={handleNav}
        loading={loading.getfaccloud}
        onRefresh={handleRefresh}
        onSelect={handleSelectEstacion}
      />
      <DangerMessage
        open={dangerMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
      <WarningMessage
        open={warningMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
    </>
  );
}
