import { Info } from "@mui/icons-material";
import { Grid, InputAdornment, MenuItem, Tooltip } from "@mui/material";
import { IGasSelectField, IGasTextField } from "@paul-igas/igas-react-components";

export const DatosEstacion = ({ clavesUnidad, timeZones, errors }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <IGasTextField label='Razón Social*' name='RazonSocial' maxLength={200} error={errors?.RazonSocial} />
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasTextField
          label='RFC Representante Legal'
          name='RFCRepresentanteLegal'
          maxLength={13}
          error={errors?.RFCRepresentanteLegal}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasTextField label='RFC*' name='RFC' maxLength={13} error={errors?.RFC} />
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasTextField
          label='Nombre Representante Legal'
          name='RepresentanteLegal'
          maxLength={200}
          error={errors?.RepresentanteLegal}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasTextField
          label='Clave Identificación*'
          name='ClaveIdentificacion'
          maxLength={4}
          error={errors?.ClaveIdentificacion}
          inputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Tooltip
                  disableFocusListener
                  enterTouchDelay={1}
                  leaveTouchDelay={5000}
                  title='Este valor se utiliza para generar la Clave de Instalación requerida en los archivos de control volumétrico.'
                >
                  <Info color='primary' />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <IGasSelectField label='Unidad de Medida*' name='IdClaveUnidad' defaultValue='0' error={errors?.IdClaveUnidad}>
          <MenuItem value='0' disabled>
            <em>Seleccione unidad de medida</em>
          </MenuItem>
          {clavesUnidad.map((i) => (
            <MenuItem
              key={i.idClaveUnidad}
              value={i.idClaveUnidad}
              style={{ maxWidth: "400px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "normal" }}
            >
              {i.descripcion}
            </MenuItem>
          ))}
        </IGasSelectField>
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasTextField
          label='Domicilio Fiscal*'
          name='DomicilioFiscal'
          maxLength={300}
          error={errors?.DomicilioFiscal}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <IGasSelectField
          label='Zona Horaria*'
          name='UTCTimeZone'
          defaultValue='Central Standard Time (Mexico)'
          error={errors?.ZonaHoraria}
        >
          <MenuItem value='0' disabled>
            <em>Seleccione Zona Horaria</em>
          </MenuItem>
          {timeZones.map((i) => (
            <MenuItem
              key={i.utc}
              value={i.name}
              style={{ maxWidth: "400px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "normal" }}
            >
              {`(${i.utc}) ${i.name}`}
            </MenuItem>
          ))}
        </IGasSelectField>
      </Grid>

      <Grid item xs={12} sm={6}>
        <IGasTextField name='Email' label='Correo Electrónico*' maxLength={100} error={errors?.Email} />
      </Grid>
    </Grid>
  );
};

export default DatosEstacion;
