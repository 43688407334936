import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Anonymous } from "../../layout/auth";
import { Loading } from "./loading";


const signoutCallback = (navigate) => {
    navigate('/');
}

export const SignoutOidc = () => {
    let navigate = useNavigate();

    useEffect(() => {
        signoutCallback(navigate);
    });

    return (<Anonymous><Loading /></Anonymous>)
}