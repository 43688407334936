import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteDialog,
  DetalleDialog,
  InsertDialog,
  OptionTableRow,
  UpdateDialog,
} from "../../components/catalogos/suscripciones";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { clearErrors, getAll, setSuscripcionIdSelected } from "../../store/catalogos/suscripciones";
import { Catalogo, ColumnAlign, ColumnPriority, DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { Tooltip } from "@mui/material";

const CELL_CONFIRM = (value) =>
  value.estatusOperativo === 0 ? (
    <Tooltip title='Nuevo' arrow>
      <FiberManualRecordIcon fontSize='small' style={{ color: "#42a5f5" }} />
    </Tooltip>
  ) : value.estatusOperativo === 1 ? (
    <Tooltip title='Activo' arrow>
      <FiberManualRecordIcon fontSize='small' style={{ color: "#16FB03" }} />
    </Tooltip>
  ) : value.estatusOperativo === 2 ? (
    <Tooltip title='Vencido' arrow>
      <FiberManualRecordIcon fontSize='small' style={{ color: "#FF0000" }} />
    </Tooltip>
  ) : (
    <Tooltip title='Baja' arrow>
      <FiberManualRecordIcon fontSize='small' style={{ color: "#000000" }} />
    </Tooltip>
  );

const TABLE_DEFAULT = {
  columns: [
    { text: "Descripción", name: "descripcion", colAlign: ColumnAlign.Left },
    { text: "Administrador", name: "administrador", colAlign: ColumnAlign.Left, colPriority: ColumnPriority.Low },
    {
      text: "Estado",
      name: "estatusOperativo",
      colAlign: ColumnAlign.Center,
      colPriority: ColumnPriority.Normal,
      icon: true,
      renderCell: CELL_CONFIRM,
    },
    { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
  ],
};

export function Suscripciones() {
  const dispatch = useDispatch();
  const { loading, suscripciones, error } = useSelector((state) => state.subscriptions);

  const modalInsert = useModal(false);
  const modalEdit = useModal(false);
  const modalDelete = useModal(false);
  const modalDetalle = useModal(false);

  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const handleRefresh = () => {
    dispatch(getAll());
  };

  const handleSelectSuscripcion = (id) => {
    dispatch(setSuscripcionIdSelected(id));
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo
        title='Contratos'
        table={TABLE_DEFAULT}
        data={suscripciones}
        loading={loading.getAll}
        onRefresh={handleRefresh}
        onInsert={modalInsert.open}
        rowOptions={
          <OptionTableRow
            onEdit={modalEdit.open}
            onDelete={modalDelete.open}
            onDetalle={modalDetalle.open}
            onSelect={handleSelectSuscripcion}
            // onAdmin={modalUserAdmin.open}
            // onStations={modalStations.open}
            ocultardetalle={false}
          />
        }
      />

      <InsertDialog open={modalInsert.isShowing} onClose={modalInsert.close} postSubmit={handleRefresh} />
      <UpdateDialog open={modalEdit.isShowing} onClose={modalEdit.close} postSubmit={handleRefresh} />
      <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleRefresh} />
      <DetalleDialog open={modalDetalle.isShowing} onClose={modalDetalle.close} postSubmit={handleRefresh} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
}
