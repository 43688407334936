import api from "../service";

const _controller = "bitacoraMatriz/servicios/";
 export async function GetLogs(params) {
   const res = await api.get(`${_controller}selectLastHour`, { params });
   return res.data;
 }

export default {
  GetLogs,
};
