import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataTable,
  DetailAlign,
  DetailDataType,
  DetailInfo,
  ColumnAlign,
  ColumnPriority,
  ColumnType,
} from "@paul-igas/igas-react-components";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLicenciasByIdEstacion } from "../../../../store/consultas/licencias";

export const DetailLicencia = ({ open, onClose }) => {
  const { licencia } = useSelector((state) => state.licencias);
  const [estadoLicencia, setEstadoLicencia] = useState("");
  const [colorEstado, setColorEstado] = useState("");
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  useEffect(() => {
    switch (licencia.estatus) {
      case 1:
        setEstadoLicencia("Activa");
        setColorEstado("#00cc00");
        break;
      case 2:
        setEstadoLicencia("Revocada");
        setColorEstado("#000000");
        break;
      case 3:
        setEstadoLicencia("Por Vencer");
        setColorEstado("#F3F781");
        break;
      case 4:
        setEstadoLicencia("Vencida");
        setColorEstado("#FF0000");
        break;
      default:
        setEstadoLicencia("En Prórroga");
        setColorEstado("#FF8000");
    }
  }, [licencia]);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={"sm"}
        scroll={"paper"}
        style={{ maxHeight: "600px" }}
      >
        <DialogTitle onClose={onClose}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              Detalle de licencia
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box bgcolor='default' border={2} borderRadius={5} borderColor={colorEstado} textAlign={"center"}>
                <Typography color={colorEstado}>{estadoLicencia}</Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <DetailInfo title='Permiso CRE' description={licencia.cre} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DetailInfo title='Nombre' description={licencia.nombre} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DetailInfo title='Razón social' description={licencia.razonSocial} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DetailInfo title='Fecha registro' dataType={DetailDataType.Date} description={licencia.fechaRegistro} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DetailInfo title='Vigencia' dataType={DetailDataType.Date} description={licencia.vigencia} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DetailInfo title='Días tolerancia' description={licencia.prorroga} dataAlign={DetailAlign.Right} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DetailInfo title='Módulos autorizados' description={licencia.modulosAutorizados} />
            </Grid>

            <Grid item xs={12} sm={12}>
              <DetailInfo title='Historial de licencias' />
              <ListaLicencias idEstacion={licencia.idEstacion} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='secondary' disableElevation>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DetailLicencia;

const ListaLicencias = ({ idEstacion }) => {
  const dispatch = useDispatch();

  const { loading, licencias } = useSelector((state) => state.licencias);
  const loadingDialog = loading.getLicenciasByIdEstacion;
  const initLista = () => {
    dispatch(getLicenciasByIdEstacion(idEstacion));
  };

  useEffect(initLista, []);

  const CELL_ESTATUS = (value) =>
    value.estatus === 1 ? (
      <Tooltip title='Activo' arrow>
        <FiberManualRecordIcon fontSize='small' style={{ color: "#00cc00" }} />
      </Tooltip>
    ) : value.estatus === 2 ? (
      <Tooltip title='Revocado' arrow>
        <FiberManualRecordIcon fontSize='small' style={{ color: "#000000" }} />
      </Tooltip>
    ) : value.estatus === 3 ? (
      <Tooltip title='Por Vencer' arrow>
        <FiberManualRecordIcon fontSize='small' style={{ color: "#F3F781" }} />
      </Tooltip>
    ) : value.estatus === 4 ? (
      <Tooltip title='Vencido' arrow>
        <FiberManualRecordIcon fontSize='small' style={{ color: "#FF0000" }} />
      </Tooltip>
    ) : (
      <Tooltip title='En Prórroga' arrow>
        <FiberManualRecordIcon fontSize='small' style={{ color: "#FF8000" }} />
      </Tooltip>
    );

  const TABLE_LICENCIAS = {
    columns: [
      {
        text: "Registro",
        name: "fechaRegistro",
        colAlign: ColumnAlign.Left,
        colType: ColumnType.Date,
        colPriority: ColumnPriority.Low,
      },
      {
        text: "Vigencia",
        textAlt: "Vigencia",
        name: "vigencia",
        colAlign: ColumnAlign.Left,
        colType: ColumnType.Date,
      },
      {
        text: "Días tolerancia",
        textAlt: "Tolerancia",
        name: "prorroga",
        colAlign: ColumnAlign.Center,
        colType: ColumnType.DataKey,
        digitos: 2,
      },
      {
        text: "Estado",
        name: "estatus",
        colAlign: ColumnAlign.Center,
        renderCell: CELL_ESTATUS,
        icon: true,
      },
    ],
  };
  return (
    <>
      {licencias && licencias.licenciasEstacion && (
        <DataTable
          table={TABLE_LICENCIAS}
          data={licencias.licenciasEstacion}
          loading={loadingDialog}
          showPagination={false}
        />
      )}
    </>
  );
};
