import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeleteTicket, GetTicketsByVersion,GetTokenTickets, PostTicket } from "../../api/services/entregables/versiones";
import { hasErrorRedux } from "../../api/services/service";

export const getTicketsByIdVersion = createAsyncThunk(
  "api/Versiones/TicketsVersion/",
  async (params, { rejectWithValue }) => {
    try {
      return await GetTicketsByVersion(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const getTokenTickets = createAsyncThunk(
  "api/Versiones/TokenTickets/",
  async (params, { rejectWithValue }) => {
    try {
      return await GetTokenTickets(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const postTicket = createAsyncThunk(
  "postTicket/",
  async (params, { rejectWithValue }) => {
    try {
      return await PostTicket(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const deleteTicket = createAsyncThunk(
  "deleteTicket/idTicket",
  async (params, { rejectWithValue }) => {
    try {
      return await DeleteTicket(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const initialState = {
  ticket: {},
  tickets: [],
  errorTicket: {},
  filter: {
    evento: { state: false, value: 0},
  },
  loadingTicket: {
    getTicketsOfVersion: false,
  },
};

const slice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTicket: (state, action) => {
      state.ticket = action.payload;
    },
    clearErrorsTickets: (state) => {
      state.errorTicket = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getTicketsByIdVersion.pending, (state) => {
      state.loadingTicket.getTicketsOfVersion = true;
    }),
      builder.addCase(getTicketsByIdVersion.fulfilled, (state, action) => {
        state.errorTicket = {};
        state.loadingTicket.getTicketsOfVersion = false;
        state.tickets = action.payload;
      }),
      builder.addCase(getTicketsByIdVersion.rejected, (state, action) => {
        state.errorTicket = action.payload;
        state.loadingTicket.getTicketsOfVersion = false;
        state.tickets = [];
      });
  },
});

export default slice.reducer;

export const { setFilter, clearErrorsTickets, setTicket} = slice.actions;
