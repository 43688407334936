import { Button, ButtonGroup, FormControl, MenuItem, Select } from "@mui/material";
import { AccordionFilter } from "@paul-igas/igas-react-components";
import { getValues } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../store/consultas/faccts/facCloudBitacora";

const menuItems = [
  <MenuItem key={1} value={1}>
    Ping
  </MenuItem>,
  <MenuItem key={2} value={2}>
    Consulta Ticket
  </MenuItem>,
  <MenuItem key={3} value={3}>
    Consulta Forma de Pago y Uso CFDI
  </MenuItem>,
  <MenuItem key={4} value={4}>
    Registro Factura
  </MenuItem>,
  <MenuItem key={5} value={5}>
    Envio de Correo Factura
  </MenuItem>,
  <MenuItem key={6} value={6}>
    Consulta PDF XML
  </MenuItem>,
  <MenuItem key={7} value={7}>
    Modificación Impresion Factura
  </MenuItem>,
  <MenuItem key={8} value={8}>
    Ping Callback
  </MenuItem>,
  <MenuItem key={9} value={9}>
    Consulta Ticket Callback
  </MenuItem>,
  <MenuItem key={10} value={10}>
    Consulta Forma de Pago y Uso CFDI Callback
  </MenuItem>,
  <MenuItem key={11} value={11}>
    Registro Factura Callback
  </MenuItem>,
  <MenuItem key={12} value={12}>
    Envio de Correo Factura Callback
  </MenuItem>,
  <MenuItem key={13} value={13}>
    Consulta PDF XML Callback
  </MenuItem>,
  <MenuItem key={14} value={14}>
    Modificación Impresion Factura Callback
  </MenuItem>,
  <MenuItem key={15} value={15}>
    Ping Service
  </MenuItem>,
  <MenuItem key={16} value={16}>
    Monitor Service
  </MenuItem>,
];

export function FilterDrawer({ open, onClose }) {
  const { filter } = useSelector((state) => state.facCloudBitacora);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(filter);
  const { handleSubmit, control, reset } = useForm();

  function initDrawer() {
    if (open) {
      reset(getValues(filter));
    }
  }

  const onSubmit = (data) => {
    const array = Object.entries(filters);
    const values = array.map(([key, value]) => [key, { ...value, value: data[key] }]);
    const result = Object.fromEntries(values);
    dispatch(setFilter(result));
    onClose();
  };

  function handleCheckChange(event) {
    const name = event.target.name;
    const checked = event.target.checked;
    const values = filters[name];

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: { ...values, state: checked },
    }));
  }

  menuItems.sort((a, b) => a.props.children.localeCompare(b.props.children));

  useEffect(initDrawer, [open]);

  return (
    <div style={{ width: "100%" }}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        <AccordionFilter name='evento' label='Tipo Evento' filters={filters} onChange={handleCheckChange}>
          <FormControl fullWidth size='small'>
            <Controller
              name='evento'
              defaultValue={filters.evento.value}
              control={control}
              render={({ field }) => (
                <Select {...field} variant='standard'>
                  {menuItems}
                </Select>
              )}
            />
          </FormControl>
        </AccordionFilter>
        <ButtonGroup
          disableElevation
          variant='contained'
          color='secondary'
          style={{
            marginTop: 16,
            marginBottom: 16,
            width: "100%",
          }}
          fullWidth
        >
          <Button onClick={onClose}>Cancelar</Button>
          <Button type='submit'>Aplicar</Button>
        </ButtonGroup>
      </form>
    </div>
  );
}
