import api from "../service";

const _controller = `entregables/Versiones`;

export async function GetTicketsDev() {
  const response = await api.get(`${_controller}/LastByTickets`);
  return response.data;
}

export default { GetTicketsDev };
