import { AddCircle } from "@mui/icons-material";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { useModal } from "@paul-igas/igas-react-hooks";
import { RegistrarEntregable } from "../dialogs/entregables/registrarEntregable";
import { ColumnAlign, ColumnPriority, HeaderIGas, SimpleTable } from "@paul-igas/igas-react-components";
import { useEffect, useState } from "react";
import HashAbreviado from "../hashAbreviado";
import OptionTableRowDelete from "../optionTableRowSteps";

const CELL_HASH = (value) => <HashAbreviado data={value} />;

const FORM_DEFAULT = {
    columns: [
        {
            text: "Descripción",
            name: "descripcion",
            colPriority: ColumnPriority.High,
        },
        {
            text: "Hash",
            name: "hash",
            colPriority: ColumnPriority.High,
            renderCell: CELL_HASH,
        },
        { text: "", name: "action", colPriority: ColumnPriority.High, colAlign: ColumnAlign.Center, options: true, icon: true },
    ],
};

export function EntregablesStep({ data }) {
    const { actualizaModeloEntregables, modeloEntregables, errorsSteps, actualizaErrores } = data

    const [loading, setLoading] = useState(false);
    const [listaEntregables, setListaEntregables] = useState([]);

    const [errors, setErrors] = useState({});


    const nuevaEntregablesDialog = useModal(false);
    const openNuevoEntregableDialog = () => {
        nuevaEntregablesDialog.open();
    };

    const onSubmitDeleteEntregable = (updateEntregables) => {
        if (validateTable(updateEntregables)) return;
        setListaEntregables(updateEntregables);
        actualizaModeloEntregables(updateEntregables);
    };

    const validateTable = (listaEntregablesNuevos) => {
        const newErrors = { listaEntregables: "" };
        if (listaEntregables.some(obj => obj.hash === listaEntregablesNuevos.hash)) {
            newErrors.listaEntregables = "No se pueden repetir el hash";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(prevErrors => {
                const updatedErrors = { ...prevErrors };
                Object.keys(newErrors).forEach(key => {
                    updatedErrors[key] = newErrors[key];
                });
                return updatedErrors;
            });
            actualizaErrores({ ...errorsSteps, entregables: { ...errorsSteps.entregables, ...newErrors } });
            return !Object.values(newErrors).every((x) => x === "");
        }
    }

    const onSubmitEntregable = (nuevoArreglo) => {
        if (validateTable(nuevoArreglo)) return;
        setLoading(true);
        setListaEntregables(nuevoArreglo);
        actualizaModeloEntregables(nuevoArreglo);
        setLoading(false);
    };
    const initLista = () => {
        setListaEntregables(modeloEntregables);
        setErrors(errorsSteps.entregables);
    }
    useEffect(() => {
        initLista();
    }, [modeloEntregables, errorsSteps]);
    return (
        <>
            <Grid container spacing={2} style={{ marginTop: 6, marginBottom: 8 }}>
                <Grid item md={6} xs={6}>
                    <HeaderIGas title="Entregables" />
                </Grid>
                <Grid item md={6} xs={6}>
                    <Tooltip title='Agregar entregable'>
                        <Button
                            style={{ minWidth: 0 }}
                            size='small'
                            disableElevation
                            onClick={openNuevoEntregableDialog}
                        >
                            <AddCircle />
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: 6 }}>
                <Grid item xs={12} md={8}>
                    <SimpleTable
                        table={FORM_DEFAULT}
                        data={listaEntregables}
                        showPagination={false}
                        loading={loading}
                        rowOptions={<OptionTableRowDelete lista={listaEntregables} onSubmitDelete={onSubmitDeleteEntregable} />}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    {Boolean(errors?.listaEntregables) && (
                        <Typography variant='caption' marginLeft={2} style={{ color: "red" }}>{`* ${errors?.listaEntregables}`}</Typography>
                    )}
                </Grid>
            </Grid>
            <RegistrarEntregable open={nuevaEntregablesDialog.isShowing} onClose={nuevaEntregablesDialog.close} data={data} postSubmit={onSubmitEntregable} />
        </>
    );
}

