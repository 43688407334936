import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetLicenciasByIdEstacion } from "../../api/services/consultas/licencias";
import { hasErrorRedux } from "../../api/services/service";
import { getOnlyActiveValues } from "@paul-igas/igas-react-hooks";

export const getLicenciasByIdEstacion = createAsyncThunk(
  "servicios/getLicenciasByIdEstacion",
  async (params, { rejectWithValue }) => {
    try {
      return await GetLicenciasByIdEstacion(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const initialState = {
  licencia: {},
  licencias: [],
  error: {},
  filter: {
    estatus: { state: false, value: 1, label: "Estado" },
  },
  loading: {
    getLicenciasByIdEstacion: false,
  },
};

const slice = createSlice({
  name: "licencias",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setLicencia: (state, action) => {
      state.licencia = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getLicenciasByIdEstacion.pending, (state) => {
      state.loading.getLicenciasByIdEstacion = true;
    }),
      builder.addCase(getLicenciasByIdEstacion.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getLicenciasByIdEstacion = false;
        state.licencias = action.payload;
      }),
      builder.addCase(getLicenciasByIdEstacion.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getLicenciasByIdEstacion = false;
        state.licencias = [];
      });
  },
});

export default slice.reducer;

export const { setFilter, clearErrors, setLicencia } = slice.actions;
