import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Catalogo,
  ColumnAlign,
  ColumnPriority,
  ColumnType,
  DangerMessage,
  WarningMessage,
} from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useNavigate } from "react-router-dom";
import  {
  getversionesbyid,
  clearErrors,
} from "../../store/entregables/modulosdetalle";
import { setVersiones } from "../../store/entregables/versiones";
import { OptionEntregables } from "../../components/entregables/optionEntregables";
import { HashAbreviado } from "../../components/entregables";

const HASH = (value) => <HashAbreviado data={value} />;

const DESCARGA = (value) => {
  const descarga = { id: value.idDescarga };
  return <OptionEntregables data={descarga} />;
};

const TABLE_DEFAULT = {
  columns: [
    {
      text: "Versión",
      name: "noVersion",
      linkDetail: true,
      colAlign: ColumnAlign.Left,
      colPriority: ColumnPriority.High,
    },
    {
      text: "Publicación",
      name: "publicacion",
      colAlign: ColumnAlign.Left,
      colType: ColumnType.DateTime,
      colPriority: ColumnPriority.High,
    },
    {
      text: "Hash",
      name: "hash",
      colAlign: ColumnAlign.Center,
      renderCell: HASH,
      colPriority: ColumnPriority.Normal,
    },
    {
      text: "Descarga",
      name: "pathEntregables",
      renderCell: DESCARGA,
      colAlign: ColumnAlign.Center,
      colPriority: ColumnPriority.High,
      icon: true,
    },
  ],
};

export function DetalleModulo() {
  const dispatch = useDispatch();
  const { loading, versiones, error } = useSelector(
    (state) => state.modulosdetalle
  );
  const { modulo } = useSelector((state) => state.modulos);
  const navigate = useNavigate();
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  useEffect(() => {
    if (modulo.id === 0) {
      navigate("/entregables/modulos");
    }
  }, []);

  const handleRefresh = () => {
    if (modulo.id != 0) {
      dispatch(getversionesbyid(modulo.id));
    }
  };

  const handleSelectVersion = (id) => {
    const versionSelectionada = versiones.find(
      (versiones) => versiones.id === id
    );
    dispatch(setVersiones(versionSelectionada));
  };

  const handleNav = () => {
    navigate("/entregables/detallesDeVersiones");
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo
        title="Detalle de Módulo"
        subtitle={modulo.descripcion}
        table={TABLE_DEFAULT}
        data={versiones}
        loading={loading.getfaccloud}
        onRefresh={handleRefresh}
        onDetail={handleNav}
        onSelect={handleSelectVersion}
      />
      <DangerMessage
        open={dangerMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
      <WarningMessage
        open={warningMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
    </>
  );
}
