import { createContext, useContext } from "react";
import { useTheme, createTheme } from "@mui/material";
import Default from "../../styles/theme/default";

const AuthContext = createContext();

export const UseAuthContext = () => useContext(AuthContext);
export const UseAuthTheme = () => useTheme(Default);
export const AuthTheme = createTheme();

export default AuthContext;