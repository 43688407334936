import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetfacCloud } from "./../../../api/services/consultas/facCloud";
import { hasErrorRedux } from "../../../api/services/service";

export const getfaccloud = createAsyncThunk(
  "bitacorafaccts/estaciones",
  async (params, { rejectWithValue }) => {
    try {
      return await GetfacCloud(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const initialState = {
  estacion: { id: 0 },
  estaciones: [],
  error: {},
  loading: {
    getfaccloud: false,
  },
};

const slice = createSlice({
  name: "facCloud",
  initialState,
  reducers: {
    setEstacion: (state, action) => {
      state.estacion = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getfaccloud.pending, (state) => {
      state.loading.getfaccloud = true;
    }),
      builder.addCase(getfaccloud.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getfaccloud = false;
        state.estaciones = action.payload;
      }),
      builder.addCase(getfaccloud.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getfaccloud = false;
        state.estaciones = [];
      });
  },
});

export default slice.reducer;

export const { clearErrors, setEstacion } = slice.actions;
