import { Grid, LinearProgress, Typography } from "@mui/material";
import { ReactComponent as LogoIGas } from '../../assets/img/logos/IGas150.svg';

export const Loading = () => {
    return (
        <Grid
            style={{ height: '50hv' }}
            container
            direction='column'
            justifyItems='center'
            alignItems='center'
        >
            <LogoIGas />
            <LinearProgress sx={{ width: 200 }} />
            <Typography variant="caption">Conectando al servidor...</Typography>
        </Grid>
    );
}