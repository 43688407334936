import api from "../service";
const _controller = "suscripcion/servicios";
 export async function GetPanelEstaciones() {
   const res = await api.get(`${_controller}/panelEstaciones`);
   return res.data;
 }



export default { GetPanelEstaciones };
