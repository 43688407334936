import React, { useState } from "react";
import { IconButton, Tooltip, } from "@mui/material";
import { RemoveCircle } from "@mui/icons-material";


export function OptionTableRowDelete(props) {
    const { lista = {}, onSubmitDelete = () => { } } = props;
    const onDelete = (datos) => {
        let array = lista.filter((x) => x.index !== datos.index);
        onSubmitDelete(array);
    };
    return (
        <IconButton size='small' color='error' onClick={() => onDelete(props.data)}>
            <Tooltip title='Eliminar'>
                <RemoveCircle />
            </Tooltip>
        </IconButton>
    );
}

export default OptionTableRowDelete;
