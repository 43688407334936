
export function NotFound() {

    return (
        <div>
            <div style={{ textAlign: "center", paddingTop: 40 }}>
                <h1>404</h1>
                <p>Oops!</p>
                <p>
                    <i>El recurso solicitado no ha sido encontrado.</i>
                </p>
            </div>
        </div>
    )
}