import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetClaveUnidadList,
  GetPermisos,
  GetClaveInstalacionList,
  GetTipoCaracterList,
  GetTimeZones,
  InsertStation,
  GetIdHidrocarburos,
  GetAllStations,
} from "./../../api/services/catalogos/estaciones";
import { hasErrorRedux } from "../../api/services/service";

export const insert = createAsyncThunk("estacion/insert", async (params, { rejectWithValue }) => {
  try {
    return await InsertStation(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getAllStations = createAsyncThunk("Servicios/selectAll", async (params, { rejectWithValue }) => {
  try {
    return await GetAllStations(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getClaveUnidad = createAsyncThunk("servicios/getClaveUnidad", async (_, { rejectWithValue }) => {
  try {
    return await GetClaveUnidadList();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getPermisos = createAsyncThunk("servicios/getPermisos", async (_, { rejectWithValue }) => {
  try {
    return await GetPermisos();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getClaveInstalacionList = createAsyncThunk(
  "servicios/getClaveInstalacionList",
  async (_, { rejectWithValue }) => {
    try {
      return await GetClaveInstalacionList();
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const getTipoCaracterList = createAsyncThunk("servicios/getTipoCaracterList", async (_, { rejectWithValue }) => {
  try {
    return await GetTipoCaracterList();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getTimeZones = createAsyncThunk("servicios/getTimeZones", async (_, { rejectWithValue }) => {
  try {
    return await GetTimeZones();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getIdHidrocarburos = createAsyncThunk("servicios/getIdHidrocarburos", async (_, { rejectWithValue }) => {
  try {
    return await GetIdHidrocarburos();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const initialState = {
  estacion: {},
  clavesUnidad: [],
  permisos: [],
  clavesInstalacion: [],
  tiposCaracter: [],
  timeZones: [],
  idHidrocarburosList: [],
  error: {},
  loading: {
    insert: false,
    getAllStations: false,
    getClaveInstalacionList: false,
    getClaveUnidad: false,
    getPermisos: false,
    getTipoCaracterList: false,
    getTimeZones: false,
    getIdHidrocarburos: false,
  },
};

const slice = createSlice({
  name: "estaciones",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(insert.pending, (state) => {
      state.loading.insert = true;
    }),
      builder.addCase(insert.fulfilled, (state, action) => {
        state.error = {};
        state.loading.insert = false;
        state.estacion = action.payload;
      }),
      builder.addCase(insert.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.insert = false;
      }),
      builder.addCase(getAllStations.pending, (state) => {
        state.loading.getAllStations = true;
      }),
      builder.addCase(getAllStations.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getAllStations = false;
        state.estaciones = action.payload;
      }),
      builder.addCase(getAllStations.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getAllStations = false;
        state.estaciones = [];
      }),
      builder.addCase(getClaveInstalacionList.pending, (state) => {
        state.loading.getClaveInstalacionList = true;
      }),
      builder.addCase(getClaveInstalacionList.fulfilled, (state, action) => {
        state.clavesInstalacion = action.payload;
        state.loading.getClaveInstalacionList = false;
        state.error = {};
      }),
      builder.addCase(getClaveInstalacionList.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getClaveInstalacionList = false;
        state.clavesInstalacion = [];
      }),
      builder.addCase(getClaveUnidad.pending, (state) => {
        state.loading.getClaveUnidad = true;
      }),
      builder.addCase(getClaveUnidad.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getClaveUnidad = false;
        state.clavesUnidad = action.payload;
      }),
      builder.addCase(getClaveUnidad.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getClaveUnidad = false;
        state.clavesUnidad = [];
      }),
      builder.addCase(getPermisos.pending, (state) => {
        state.loading.getPermisos = true;
      }),
      builder.addCase(getPermisos.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getPermisos = false;
        state.permisos = action.payload;
      }),
      builder.addCase(getPermisos.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getPermisos = false;
        state.permisos = [];
      }),
      builder.addCase(getTipoCaracterList.pending, (state) => {
        state.loading.getTipoCaracterList = true;
      }),
      builder.addCase(getTipoCaracterList.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getTipoCaracterList = false;
        state.tiposCaracter = action.payload;
      }),
      builder.addCase(getTipoCaracterList.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getTipoCaracterList = false;
        state.tiposCaracter = [];
      }),
      builder.addCase(getTimeZones.pending, (state) => {
        state.loading.getTimeZones = true;
      }),
      builder.addCase(getTimeZones.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getTimeZones = false;
        state.timeZones = action.payload;
      }),
      builder.addCase(getTimeZones.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getTimeZones = false;
        state.timeZones = [];
      }),
      builder.addCase(getIdHidrocarburos.pending, (state) => {
        state.loading.getIdHidrocarburos = true;
      }),
      builder.addCase(getIdHidrocarburos.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getIdHidrocarburos = false;
        state.idHidrocarburosList = action.payload;
      }),
      builder.addCase(getIdHidrocarburos.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getIdHidrocarburos = false;
        state.idHidrocarburosList = [];
      });
  },
});

export default slice.reducer;

export const { clearErrors } = slice.actions;
