import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { boldText } from "../../helpers/utils";
import { EstadosVersion } from "../../../utils/enums";

export function ConfirmDialog({ open, onClose, onConfirm, info }) {
    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        onClose();
    };
    return (
        <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' scroll='paper'>
            <DialogTitle>
                Confirmar la eliminación de la versión:
                <br />
                {info.producto}  {info.noVersion}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Al confirmar la eliminación de versión se perderá toda información guardada y relacionada a esa versión como ticket y entregables.
                    {<br />}
                    {<br />} {boldText("¿Proceder con la eliminación?")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' disableElevation onClick={handleClose}>
                    No
                </Button>
                <Button color='secondary' disableElevation onClick={onConfirm}>
                    Si
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function ConfirmDialogEstatus({ open, onClose, onConfirm, info}) {
    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        onClose();
    };
    return (
        <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' scroll='paper'>
            <DialogTitle>
                Confirmar el cambio de estado:
                <br />
                {info.descripcionEstado} a {info.estado === EstadosVersion.Dev ? "QA" : "Liberado"}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Se cambiará el estado a {info.estado === EstadosVersion.Dev ? "QA" : "Liberado"}
                    {<br />}
                    {<br />} {boldText("¿Seguro de cambiar el estado?")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' disableElevation onClick={handleClose}>
                    No
                </Button>
                <Button color='secondary' disableElevation onClick={onConfirm}>
                    Si
                </Button>
            </DialogActions>
        </Dialog>
    );
}
