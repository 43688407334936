import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAllStations, GetStations, UpdateStations } from "../../api/services/catalogos/estaciones";
import { GetAll, GetId, Insert, Update, Delete, GetSuscripciones } from "../../api/services/catalogos/suscripciones";
import { InsertUserAdmin } from "../../api/services/catalogos/usuarioEstacion";
import { hasErrorRedux } from "../../api/services/service";

export const getAll = createAsyncThunk("servicios/getAll", async (_, { rejectWithValue }) => {
  try {
    return await GetAll();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getSuscripciones = createAsyncThunk("servicios/consultarSuscripciones", async (_, { rejectWithValue }) => {
  try {
    return await GetSuscripciones();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getId = createAsyncThunk("servicios/selectId", async (params, { rejectWithValue }) => {
  try {
    return await GetId(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const insert = createAsyncThunk("servicios/insert", async (params, { rejectWithValue }) => {
  try {
    return await Insert(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const update = createAsyncThunk("servicios/update", async (params, { rejectWithValue }) => {
  try {
    return await Update(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const remove = createAsyncThunk("servicios/delete", async (params, { rejectWithValue }) => {
  try {
    return await Delete(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const initialState = {
  suscripcion: {},
  suscripciones: [],
  suscripcionesConsulta: [],
  estaciones: [],
  error: {},
  estacionselected: {},
  suscripcionIdSelected: "",
  loading: {
    getAll: false,
    getId: false,
    insert: false,
    update: false,
    delete: false,
  },
};

const slice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setStations: (state, action) => {
      state.estacionselected = action.payload;
    },
    setStationList: (state, action) => {
      state.estaciones = action.payload;
    },
    setSuscripcionIdSelected: (state, action) => {
      state.suscripcionIdSelected = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-expressions
    builder.addCase(getAll.pending, (state) => {
      state.loading.getAll = true;
    }),
      builder.addCase(getAll.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getAll = false;
        state.suscripciones = action.payload;
      }),
      builder.addCase(getAll.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getAll = false;
        state.suscripciones = [];
      }),
      builder.addCase(getId.pending, (state) => {
        state.loading.getId = true;
      }),
      builder.addCase(getId.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getId = false;
        state.suscripcion = action.payload;
      }),
      builder.addCase(getId.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getId = false;
        state.suscripcion = {};
      }),
      builder.addCase(insert.pending, (state) => {
        state.loading.insert = true;
      }),
      builder.addCase(insert.fulfilled, (state, action) => {
        state.error = {};
        state.loading.insert = false;
      }),
      builder.addCase(insert.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.insert = false;
      }),
      builder.addCase(update.pending, (state) => {
        state.loading.update = true;
      }),
      builder.addCase(update.fulfilled, (state, action) => {
        state.error = {};
        state.loading.update = false;
      }),
      builder.addCase(update.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.update = false;
      }),
      builder.addCase(remove.pending, (state) => {
        state.loading.delete = true;
      }),
      builder.addCase(remove.fulfilled, (state, action) => {
        state.error = {};
        state.loading.delete = false;
        state.suscripcion = action.payload;
      }),
      builder.addCase(remove.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.delete = false;
        state.suscripcion = {};
      }),
      builder.addCase(getSuscripciones.pending, (state) => {
        state.loading.getSuscripciones = true;
      }),
      builder.addCase(getSuscripciones.fulfilled, (state, action) => {
        state.error = {};
        state.loading.getSuscripciones = false;
        state.suscripcionesConsulta = action.payload;
      }),
      builder.addCase(getSuscripciones.rejected, (state, action) => {
        state.error = action.payload;
        state.loading.getSuscripciones = false;
        state.suscripcionesConsulta = {};
      });
  },
});

export default slice.reducer;

export const { setSuscripcionIdSelected, clearErrors, setStations, setStationList } = slice.actions;
