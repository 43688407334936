import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import { Busqueda, DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { getSuscripciones, setStationList } from "../../../store/catalogos/suscripciones";
import { hasDispatchError } from "../../../api/services/service";
import { getAllStations } from "../../../store/consultas/estacion";

const TABLE_DEFAULT = {
  columns: [
    { text: "Permiso CRE", name: "cre" },
    { text: "Nombre", name: "nombre" },
  ],
};

const _filter = {
  filtro: {
    value: "",
  },
};

export const ConsultarEstacion = ({ open, onClose, postSubmit, stations, error }) => {
  const dispatch = useDispatch();
  const { loading, estaciones, suscripcionesConsulta } = useSelector((state) => state.subscriptions);
  const [errorMessage, setErrorMessage] = useState("");
  const [stationselect, setStationSelect] = useState(null);
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const initFilter = () => {
    _filter.filtro.value = "";
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    initFilter();
    dispatch(setStationList([]));
    onClose();
  };
  const handleRefresh = (_filter) => {
    dispatch(getAllStations(_filter))
    .then(actualizaEstaciones);
    handleSelectSuscripciones();
  };
  
  function initDialog() {
    if (open) {
      initFilter();
      dispatch(setStationList([]));
      dispatch(getAllStations(_filter))
      .then(hasDispatchError)
      .then(actualizaEstaciones)
      .catch(() => {});
    }
  }

  const actualizaEstaciones = ({ payload }) => {
    dispatch(setStationList(payload));
  }
  const handleChangerFilter = (_filter) => {
    handleRefresh(_filter.filtro);
  };
  const handleSelectitem = (id) => {
    setStationSelect(id);
  };

  const handleSelectSuscripciones = () => {
    dispatch(getSuscripciones())
      .then(hasDispatchError)
      .catch(() => {});
  };

  const handleError = () => {
    if (error !== undefined) {
      if (typeof error.message === "object") {
        var enc = new TextDecoder("utf-8");
        setErrorMessage(enc.decode(error.message));
      } else {
        setErrorMessage(error.message);
      }
      if (Boolean(error?.status)) {
        error.status <= 409 && warningMsg.open();
        error.status >= 500 && dangerMsg.open();
      }
    }
  };
  const validateStations = (newStation) => {
    let errorTemp = [];
    let existente = false;
    let bandera = stations.some((station) => station.idEstacion === newStation.id || station.id === newStation.id);
    if (suscripcionesConsulta?.length > 0) {
      existente = suscripcionesConsulta.some((subscription) => {
        return subscription.estaciones.some((station) => {
          return station.idEstacion === newStation.id || station.id === newStation.id;
        });
      });
    }

    if (bandera) {
      setErrorMessage("La estación seleccionada ya se encuentra añadida al listado.");
      errorTemp.bandera = bandera ? "La estación seleccionada ya se encuentra añadida al listado." : "";
      return;
    } else if (existente) {
      setErrorMessage("La estación seleccionada ya pertenece a un contrato existente.");
      errorTemp.existente = existente ? "La estación seleccionada ya pertenece a un contrato existente." : "";
      return;
    }
    return Object.values(errorTemp).every((x) => x === "");
  };

  const handleCloseError = () => {
    dangerMsg.close();
    warningMsg.close();
  };

  const onSubmit = () => {
    if (validateStations(stationselect)) {
      if (stationselect.nombre.length <= 1) return;
      postSubmit(stationselect);
      onClose();
    } else {
      warningMsg.open();
    }
  };
  useEffect(handleError, [error]);
  useEffect(initDialog, [open]);
  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='sm' scroll='paper'>
        {(loading.getAllStations || loading.getSuscripciones) && <LinearProgress />}
        <DialogTitle>Asignar estación</DialogTitle>
        <DialogContent dividers>
          <Busqueda
            table={TABLE_DEFAULT}
            filter={_filter}
            onSetFilter={handleChangerFilter}
            data={estaciones}
            onSelect={handleSelectitem}
            loading={loading.getAllStations}
          />
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.insert} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disabled={JSON.stringify(stationselect) === "{}"} onClick={onSubmit}>
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={errorMessage} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={errorMessage} />
    </>
  );
};

export default ConsultarEstacion;
