import React, { useEffect, useState } from "react";
import { Button, Menu, MenuItem, ListItemIcon, ListItemText, } from "@mui/material";
import { MoreVert, FileDownload, OpenInNew, RemoveCircle, Edit, ArrowForward, Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setTicket } from "../../store/entregables/ticketDetalleVersion";
import { useTheme } from "@emotion/react";
import { useModal } from "@paul-igas/igas-react-hooks";
import { ConfirmDialog, ConfirmDialogEstatus } from "./dialogs/confirmDialog";
import { hasDispatchError } from "../../api/services/service";
import { useNavigate } from "react-router-dom";
import { EstadosVersion } from "../../utils/enums";
import { deleteVersion, getversiones, putVersion,  setVersiones } from "../../store/entregables/versiones";
import { getTokenTickets } from "../../store/entregables/ticketDetalleVersion";

export function OptionTableRow(props) {
  const {
    data = {},
    onEdit = () => { },
    onDetalle = () => { },
    onDelete = () => { },
    permissionModify
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleClose = () => setAnchorEl(null);
  const { loadingTicket } = useSelector((state) => state.tickets);
  const dispatch = useDispatch();
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelect = (id) => {
    dispatch(setTicket(id));
  };

  const handleGoVtiger = () => {
    window.open(data.pathTicket, '_blank');
    handleClose();
    handleSelect(data.id);
    onDetalle();
  };
  const handleInforme = () => {
    dispatch(getTokenTickets(data.id))
      .then(({ payload }) => {
        window.open(payload, '_blank');
      });
    handleClose();
    handleSelect(data.id);
    onEdit();
  };


  return (
    <>
      <Button size='small' disableElevation style={{ minWidth: 0 }} onClick={handleOpen}>
        <MoreVert fontSize='small' color='action' />
      </Button>
      {!loadingTicket.getTicketsOfVersion && (
        <>
          {Boolean(anchorEl) && (
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ zIndex: theme.zIndex.modal + 10 }}
            >
              <MenuItem dense onClick={handleGoVtiger}>
                <ListItemIcon>
                  <OpenInNew fontSize='small' />
                </ListItemIcon>
                <span>Vtiger</span>
              </MenuItem>
              {data?.pathInforme !== '' && (<MenuItem dense onClick={handleInforme}>
                <ListItemIcon>
                  <FileDownload fontSize='small' />
                </ListItemIcon>
                <span>Informe</span>
              </MenuItem>)}
              {permissionModify && (
                <MenuItem dense onClick={()=>{onDelete(data.id)}}>
                <ListItemIcon>
                    <Delete fontSize='small' />
                  </ListItemIcon>
                  <span>Eliminar</span>
                </MenuItem>)
              }
            </Menu>
          )}
        </>
      )}
    </>
  );
}

export function OptionTableRowVersiones(props) {
  const {
    data = {},
    onRefreshDelete = () => { },
  } = props;
  const { loading, versiones } = useSelector((state) => state.versiones);
  const { profile } = useSelector((state) => state.application);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => setAnchorEl(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const confirmarDlg = useModal(false);
  const confirmarChangeDlg = useModal(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEliminar = () => {
    confirmarDlg.open();
    handleClose();
  }

  const handleDeleteVersion = () => {
    dispatch(deleteVersion(data.id)).then(hasDispatchError)
      .then(onRefreshDelete)
      .catch(() => { });
    confirmarDlg.close();
    handleClose();
  };

  const handleNextStep = async () => {
    const objetoUpdate = { id: data.id, Entregables: [], estado: data.estado === EstadosVersion.Dev ? 1 : 2 };
    dispatch(
      putVersion(objetoUpdate)
    )
      .then(hasDispatchError)
      .then(goList)
      .catch(() => { });
    confirmarDlg.close();
    handleClose();
  };

  const goList = () => {
    dispatch(getversiones());
  };

  return (
    <>
      {Boolean(profile.AdministraActualizaciones) && (
        <Button size='small' disableElevation style={{ minWidth: 0 }} onClick={handleOpen}>
          <MoreVert fontSize='small' color='action' />
        </Button>
      )
      }
      {!loading.getversiones && (
        <>
          {Boolean(anchorEl) && (
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ zIndex: theme.zIndex.modal + 10 }}
            >
              <MenuItem dense onClick={handleEliminar}>
                <ListItemIcon>
                  <RemoveCircle fontSize='small' />
                </ListItemIcon>
                <ListItemText>Eliminar</ListItemText>
              </MenuItem>
             {Boolean(data.estado != EstadosVersion.Liberado) && (
                <MenuItem dense onClick={() => { handleClose(); confirmarChangeDlg.open() }}>
                  <ListItemIcon>
                    <ArrowForward fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Enviar a {data.estado === EstadosVersion.Dev ? "QA" : "Liberado"}</ListItemText>
                </MenuItem>
              )}
            </Menu>
          )}
        </>
      )}
      <ConfirmDialog open={confirmarDlg.isShowing} onClose={confirmarDlg.close} onConfirm={handleDeleteVersion} info={data} />
      <ConfirmDialogEstatus open={confirmarChangeDlg.isShowing} onClose={confirmarChangeDlg.close} onConfirm={handleNextStep} info={data} />
    </>
  );
}
export default OptionTableRow;
