import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ColumnPriority, IGasForm, IGasTextField, SuccessMessage } from "@paul-igas/igas-react-components";
import { ColumnAlign } from "@paul-igas/igas-react-components";
import EstacionesContrato from "./components/estacionesContrato";
import { wait } from "../../helpers/utils";
import { insert } from "../../../store/catalogos/suscripciones";
import { getModelErrors, useModal } from "@paul-igas/igas-react-hooks";
import { hasDispatchError } from "../../../api/services/service";

const TABLE_INSERT = {
  columns: [
    { text: "Estación", name: "nombre" },
    { text: "CRE", name: "cre", colPriority: ColumnPriority.Low },
    { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
  ],
};

const FORM_DEFAULT = {
  descripcion: "",
  administrador: "",
};

export function InsertDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.subscriptions);
  const [errors, setErrors] = useState({});
  const data = useEstacionesInsert(open);

  const successMsg = useModal(false);

  const onSubmit = (form) => {
    dispatch(insert({ ...form, estaciones: data.stations }))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const initDialog = () => {
    if (open === true) setErrors([]);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const assignErrors = () => {
    if (error?.status === 400) setErrors(getModelErrors(error.message));
  };

  useEffect(initDialog, [open]);
  useEffect(assignErrors, [error]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='sm' scroll='paper'>
        {loading.insert && <LinearProgress />}
        <DialogTitle>Agregar contrato</DialogTitle>
        <DialogContent dividers>
          <IGasForm id='form-subs-ins-dlg' onSubmit={onSubmit} autoComplete='off'>
            <Grid container spacing={1} alignContent='space-between'>
              <Grid item xs={12}>
                <IGasTextField
                  name='descripcion'
                  defaultValue={FORM_DEFAULT.descripcion}
                  label='Descripción'
                  disabled={loading.insert}
                  error={errors?.descripcion}
                  maxLength={100}
                  autoFocus={true}
                />
              </Grid>

              <Grid item xs={12}>
                <IGasTextField
                  name='administrador'
                  label='Email usuario administrador'
                  defaultValue={FORM_DEFAULT.administrador}
                  error={errors?.administrador}
                  disabled={loading.insert}
                  maxLength={100}
                />
              </Grid>
            </Grid>
          </IGasForm>
          
          {/* Componente para administrar listado de estaciones */}
          <EstacionesContrato data={data} table={TABLE_INSERT} />
          {Boolean(errors?.estaciones) && (
            <Typography variant='caption' marginLeft={2} style={{ color: "red" }}>{`${errors?.estaciones}`}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.insert || data.refresh} onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            color='secondary'
            disableElevation
            disabled={loading.insert || data.refresh}
            type='submit'
            form='form-subs-ins-dlg'
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se guardó correctamente.' />
    </>
  );
}

export default InsertDialog;

const useEstacionesInsert = (open) => {
  const [stations, setStations] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const deleteStation = (station) => {
    let newStations = stations.filter((st) => st.id !== station.id);
    setStations(newStations);
  };

  const refreshStations = async () => {
    setRefresh(true);
    await wait(1000);
    setRefresh(false);
  };

  const initStations = () => {
    if (open === true) setStations([]);
  };

  useEffect(initStations, [open]);
  useEffect(() => {
    refreshStations();
  }, [stations]);

  return {
    stations,
    refresh,
    addStation: (station) => setStations([...stations, station]),
    deleteStation,
  };
};
