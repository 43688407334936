import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { ColumnAlign, ColumnPriority, DetailInfo } from "@paul-igas/igas-react-components";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDispatch, useSelector } from "react-redux";
import EstacionesContrato from "./components/estacionesContrato";
import { useEffect, useState } from "react";
import { hasDispatchError } from "../../../api/services/service";
import { getId } from "../../../store/catalogos/suscripciones";
import OptionTableRow from "./components/optionTableRow";
import { useModal } from "@paul-igas/igas-react-hooks";
import { UpdateDialog } from "./updateDialog";
import { DeleteDialog } from "./deleteDialog";

const CELL_ESTADO = (value) =>
  value.estatusOperativo === 0 ? (
    <Tooltip title='Nueva' arrow>
      <FiberManualRecordIcon fontSize='small' style={{ color: "#42a5f5" }} />
    </Tooltip>
  ) : value.estatusOperativo === 1 ? (
    <Tooltip title='Activa' arrow>
      <FiberManualRecordIcon fontSize='small' style={{ color: "#16FB03" }} />
    </Tooltip>
  ) : value.estatusOperativo === 2 ? (
    <Tooltip title='Vencida' arrow>
      <FiberManualRecordIcon fontSize='small' style={{ color: "#d32f2f" }} />
    </Tooltip>
  ) : (
    <Tooltip title='Baja' arrow>
      <FiberManualRecordIcon fontSize='small' style={{ color: "000000" }} />
    </Tooltip>
  );

const TABLE_DETAIL = {
  columns: [
    { text: "Estación", name: "nombre" },
    { text: "CRE", name: "cre", colPriority: ColumnPriority.Low },
    {
      text: "Estado",
      name: "estatusOperativo",
      ColumnAlign: ColumnAlign.Center,
      icon: true,
      renderCell: CELL_ESTADO,
    },
  ],
};

export function DetalleDialog({ open, onClose, postSubmit, visibleOptions = true }) {
  const dispatch = useDispatch();
  const { loading, suscripcion, suscripcionIdSelected } = useSelector((state) => state.subscriptions);
  const [data, setData] = useState({ stations: [], refresh: true });

  const modalEdit = useModal(false);
  const modalDelete = useModal(false);

  const assignSuscripcion = ({ payload }) => {
    setData({ stations: payload.estaciones, refresh: loading.getId });
  };

  const initDialog = () => {
    if (open) {
      setData({ stations: [], refresh: true });
      dispatch(getId(suscripcionIdSelected)).then(hasDispatchError).then(assignSuscripcion).catch(onClose);
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    onClose();
  };

  function handleDelete() {
    onClose();
    postSubmit();
  }

  function handleEdit() {
    initDialog();
    postSubmit();
  }

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='sm' scroll='paper'>
        {loading.update && <LinearProgress />}
        <DialogTitle>Detalle de contrato</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DetailInfo title='Descripción' description={suscripcion?.descripcion} loading={loading.getId} />
            </Grid>
            <Grid item xs={12}>
              <DetailInfo
                title='Email usuario administrador'
                description={suscripcion?.administrador}
                loading={loading.getId}
              />
            </Grid>
          </Grid>
          {/* Componente para administrar listado de estaciones */}
          <EstacionesContrato data={data} table={TABLE_DETAIL} showActions={false} />
        </DialogContent>

        <DialogActions>
          {visibleOptions && !loading.getId && (
            <OptionTableRow
              data={suscripcion}
              onEdit={modalEdit.open}
              onDelete={modalDelete.open}
              onSelect={suscripcionIdSelected}
              ocultardetalle={true}
            />
          )}
          <Button onClick={handleClose} color='secondary' disableElevation disabled={loading.getId}>
            Salir
          </Button>
        </DialogActions>
      </Dialog>

      <UpdateDialog open={modalEdit.isShowing} onClose={modalEdit.close} postSubmit={handleEdit} />
      <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleDelete} />
    </>
  );
}

export default DetalleDialog;
