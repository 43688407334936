import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Tooltip } from "@mui/material";
import { FlatButton } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { DetailFechaHoraReplicacion } from "./dialogs";

export const OptionFechaHoraReplicacion = ({ data }) => {
  const modalDetail = useModal(false);

  if (data.fechaHoraReplicacion == undefined)
    return (
      <Tooltip title='Sin Replicación' arrow>
        <FiberManualRecordIcon fontSize='small' style={{ color: "#9b9b9b" }} />
      </Tooltip>
    );
  const handleClick = () => {
    modalDetail.open();
  };

  const fechaReplicacion = new Date(data.fechaHoraReplicacion);
  const now = new Date();
  const diffMs = Math.abs(now - fechaReplicacion);

  const segundos = Math.floor(diffMs / 1000) % 60;
  const minutos = Math.floor(diffMs / (1000 * 60)) % 60;
  const horas = Math.floor(diffMs / (1000 * 60 * 60)) % 24;
  const dias = Math.floor(diffMs / (1000 * 60 * 60 * 24)) % 30;

  const componente = {
    dias,
    horas,
    minutos,
    segundos,
  };

  let color;
  let titulo;
  var diffMinutes = diffMs / (1000 * 60);
  if (diffMinutes < 5) {
    titulo = "Menor a cinco minutos.";
    color = "#00cc00";
  } else if (diffMinutes >= 5 && diffMinutes <= 10) {
    titulo = "Entre cinco y diez minutos.";
    color = "#D5D416";
  } else if (diffMinutes > 10 && diffMinutes <= 30) {
    titulo = "Entre diez y treinta minutos.";
    color = "#FF8000";
  } else {
    titulo = "Mayor a treinta minutos.";
    color = "#FF0000";
  }

  return (
    <>
      <Tooltip title="Detalle de replicación" arrow>
        <FlatButton onClick={handleClick}>
          <FiberManualRecordIcon fontSize='small' style={{ color }} />
        </FlatButton>
      </Tooltip>
      <DetailFechaHoraReplicacion
        open={modalDetail.isShowing}
        onClose={modalDetail.close}
        postSubmit={() => {}}
        data={data}
        encabezado={titulo}
        color={color}
        componente={componente}
      />
    </>
  );
};
