import { UserManager } from "oidc-client";
import { IDENTITY_CONFIG } from "../utils/authConst";

const userManager = new UserManager(IDENTITY_CONFIG);

export const isAuth = async () => {
    const user = await userManager.getUser();

    if (user === null || user === undefined) return 401;
    if (user.expired) return 401;
    if (user.profile.activo !== "True") return 403;

    return 200;
}

export const login = async () => {
    localStorage.clear();
    await userManager.signinRedirect();
}

export const logout = async () => {

    localStorage.clear();
    await userManager.signoutRedirect();
}

export const getUser = async () => {
    const _user = await userManager.getUser();
    return _user;
}
