import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getVersionesEntregables, clearErrorsEntregables,
    getTokenEntregables,
    postEntregables,
    deleteEntregables
} from "../../store/entregables/entregablesDetalleVersion";
import { Card, CardHeader, Grid, CardContent, Typography, Box, Button } from '@mui/material';
import {
    ColumnAlign,
    ColumnPriority,
    DangerMessage,
    HeaderIGas,
    SimpleTable,
    WarningMessage,
} from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useNavigate } from "react-router-dom";
import {
    getTicketsByIdVersion,
    setTicket,
    clearErrorsTickets,
    postTicket,
    deleteTicket
} from "../../store/entregables/ticketDetalleVersion";
import { Code, DoneAllRounded, ManageSearch } from "@mui/icons-material";
import { hasDispatchError } from "../../api/services/service";
import {
    OptionTableRow,
    IconoTipoTicket,
    HashAbreviado
} from "../../components/entregables";
import DownloadIcon from '@mui/icons-material/Download';
import { BotonAction, TableEntregables } from "../../components/entregables/table";
import { RegistrarEntregable } from "../../components/entregables/dialogs/entregables/registrarEntregable";
import { RegistrarTicket } from "../../components/entregables/dialogs/tickets/registraTickets";
import { HeaderDetalleVersion } from "../../components/entregables/detalleVersion";


const HASH_COMPLET = (value) => <HashAbreviado data={{ hash: value.hash }} />;

const TIPO_CASO = (value) => <IconoTipoTicket data={{ tipo: value.ticketTipo }} />;

const TABLE_DEFAULT_TICKET = {
    columns: [
        {
            text: "",
            name: "id",
            icon: true,
            colPriority: ColumnPriority.High,
            renderCell: TIPO_CASO,
        },
        {
            text: "Dev",
            name: "ticketDev",
            colPriority: ColumnPriority.Normal,
            colAlign: ColumnAlign.Left,
        },
        {
            text: "Soporte",
            name: "ticketSoporte",
            colPriority: ColumnPriority.Normal,
            colAlign: ColumnAlign.Center,
        },
        {
            text: "Descripción",
            name: "ticketDescripcion",
            colPriority: ColumnPriority.High,
            colAlign: ColumnAlign.Left
        },
        {
            text: "Hash",
            name: "hashAbrev",
            colPriority: ColumnPriority.Low,
            colAlign: ColumnAlign.Left,
            renderCell: HASH_COMPLET,
        },
        { text: "", name: "action", colPriority: ColumnPriority.High, colAlign: ColumnAlign.Center, options: true, icon: true },
    ],
};

export function DetallesVersionesPage() {
    const dispatch = useDispatch();
    const { errorEntregables, loadingEntregables } = useSelector(
        (state) => state.entregables
    );
    const { version } = useSelector((state) => state.versiones);
    const { loadingTicket, errorTicket } = useSelector((state) => state.tickets);
    const { profile } = useSelector((state) => state.application);
    const navigate = useNavigate();

    const dangerMsg = useModal(false);
    const warningMsg = useModal(false);
    const modalEdit = useModal(false);
    const modalDetalle = useModal(false);
    const nuevaEntregablesDialog = useModal(false);
    const nuevoTicketDialog = useModal(false);

    const [checkedItems, setCheckedItems] = useState({});

    const handleSelectRegistroTicket = (id) => {
        const registroEncontrado = ticketsOfVersion.find((registro) => registro.id === id);
        dispatch(setTicket(registroEncontrado));
    };

    const handleError = () => {
        if (Boolean(errorEntregables?.status)) {
            errorEntregables.status === 409 && warningMsg.open();
            errorEntregables.status === 500 && dangerMsg.open();
        }
        if (Boolean(errorTicket?.status)) {
            errorTicket.status === 409 && warningMsg.open();
            errorTicket.status === 500 && dangerMsg.open();
        }
    };

    const handleCloseError = () => {
        dispatch(clearErrorsEntregables());
        dispatch(clearErrorsTickets());
        dangerMsg.close();
        warningMsg.close();
    };
    const [entregablesOfVersion, setEntregables] = useState([]);
    const [ticketsOfVersion, setTickets] = useState([]);
    const actualizaModulos = ({ payload }) => {
        setEntregables(payload);
    }
    const actualizaTickets = ({ payload }) => {
        setTickets(payload);
    }
    const initLista = () => {
        if (version.id === 0) {
            navigate("/entregables/versiones");
        }
        dispatch(getVersionesEntregables(version)).then(hasDispatchError)
            .then(actualizaModulos)
            .catch(() => { });

        dispatch(getTicketsByIdVersion(version)).then(hasDispatchError)
            .then(actualizaTickets)
            .catch(() => { });
    };

    const getIcon = (estado) => {
        switch (estado) {
            case 1:
                return <ManageSearch fontSize="large" />;
            case 2:
                return <DoneAllRounded fontSize="large" />;
            default:
                return <Code fontSize="large" />;
        }
    };

    const handleSelectToDownload = (param) => {
        if (Object.keys(param).length === 0) {
            setCheckedItems({});
        } else {
            setCheckedItems(prevCheckedItems => ({
                ...prevCheckedItems,
                ...param
            }));
        }
    };

    const handleDownloadSelect = () => {
        const itemToDownload = Object.keys(checkedItems).filter(key => checkedItems[key] === true);
        if (itemToDownload.length > 0) {
            itemToDownload.forEach((key, index) => {
                dispatch(getTokenEntregables(key))
                    .then(({ payload }) => {
                        window.open(payload, '_blank')
                    });

            });
        }
    };

    const openNuevoEntregableDialog = () => {
        nuevaEntregablesDialog.open();
    };

    const openNuevoTicketDialog = () => {
        nuevoTicketDialog.open();
    };

    const onSubmitEntregable = (nuevoEntregable) => {
        const entregable = nuevoEntregable[0];
        const dtoAGuardar = {
            ...entregable,
            versionId: version.id
        };
        dispatch(postEntregables(dtoAGuardar)).then(hasDispatchError)
            .then(initLista)
            .catch(() => { });
    };

    const onSubmitTicket = (nuevoTicket) => {
        const ticket = nuevoTicket[0];
        const dtoAGuardar = { 
            versionId: version.id, 
            ...ticket}
        dispatch(postTicket(dtoAGuardar)).then(hasDispatchError)
            .then(initLista)
            .catch(() => { });
    }

    const onDeleteEntregable = (id) => {
        dispatch(deleteEntregables(id)).then(hasDispatchError)
            .then(initLista)
            .catch(() => { });
    };

    const onDeleteTicket = (id) => {
        dispatch(deleteTicket(id)).then(hasDispatchError)
            .then(initLista)
            .catch(() => { });
    };
    const isAnyChecked = Object.values(checkedItems).some(value => value === true);

    useEffect(() => {
        initLista();
    }, [dispatch, navigate, version]);
    useEffect(handleError, [errorEntregables, errorTicket]);

    return (
        <>
            <Grid container spacing={2} justifyContent="space-between" >
                <Grid item xs={6} md={6}>
                    <HeaderDetalleVersion
                        initLista={initLista}
                        permissionEdit={Boolean(profile.AdministraActualizaciones)}
                    />
                </Grid>
                <Grid item xs={6} md={6} display="flex" justifyContent="flex-end">
                    {version.estado >= 0 && (
                        <Box display="flex" alignItems="center" gap={1}>
                            <Typography variant="h5">{`${version.descripcionEstado}`}</Typography>
                            {getIcon(version.estado)}
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Card variant="outlined">
                <CardHeader
                    avatar={
                        <HeaderIGas title={"Entregables"} />
                    }
                    title={
                        Boolean(profile.AdministraActualizaciones) ?
                            <BotonAction
                                title={"Agregar Entregable"}
                                onClick={openNuevoEntregableDialog}
                                action={0}
                            />
                            : null
                    }
                    action={
                        isAnyChecked &&
                        (<Button variant="contained" endIcon={<DownloadIcon />} onClick={handleDownloadSelect}>
                            Descargar seleccionados
                        </Button>)
                    }
                />
                <CardContent sx={{ p: "1px", mb: "-40px" }}>
                    <TableEntregables
                        data={entregablesOfVersion}
                        onSelect={handleSelectToDownload}
                        permissionModify={Boolean(profile.AdministraActualizaciones)}
                        onDelete={onDeleteEntregable}
                        loading={loadingEntregables.getVersionesEntregables}
                    />
                </CardContent>
            </Card>
            <Card variant="outlined" style={{ marginTop: '1%', marginBottom: '1%' }}>
                <CardHeader
                    avatar={
                        <HeaderIGas title={"Tickets"} />
                    }
                    title={
                        Boolean(profile.AdministraActualizaciones) ?
                            <BotonAction
                                title={"Agregar Ticket"}
                                onClick={openNuevoTicketDialog}
                                action={0}
                            />
                            : null
                    }
                />
                <CardContent sx={{ p: "1px", mb: "-40px" }}>
                    <SimpleTable
                        table={TABLE_DEFAULT_TICKET}
                        data={ticketsOfVersion}
                        loading={loadingTicket.getTicketsOfVersion}
                        showPagination={false}
                        rowOptions={
                            <OptionTableRow
                                onEdit={modalEdit.open}
                                onDelete={onDeleteTicket}
                                onDetalle={modalDetalle.open}
                                onSelect={handleSelectRegistroTicket}
                                permissionModify={Boolean(profile.AdministraActualizaciones)}
                            />
                        }
                    />
                </CardContent>
            </Card>
            <DangerMessage
                open={dangerMsg.isShowing}
                onClose={handleCloseError}
                text={errorEntregables?.message}
            />
            <DangerMessage
                open={dangerMsg.isShowing}
                onClose={handleCloseError}
                text={errorTicket?.message}
            />
            <WarningMessage
                open={warningMsg.isShowing}
                onClose={handleCloseError}
                text={errorEntregables?.message}
            />
            <WarningMessage
                open={warningMsg.isShowing}
                onClose={handleCloseError}
                text={errorTicket?.message}
            />
            <RegistrarEntregable open={nuevaEntregablesDialog.isShowing} onClose={nuevaEntregablesDialog.close} data={{ modeloEntregables: [] }} postSubmit={onSubmitEntregable} />
            <RegistrarTicket open={nuevoTicketDialog.isShowing} onClose={nuevoTicketDialog.close} data={{ modeloTickets: [] }} postSubmit={onSubmitTicket} />
        </>
    )
}
export default DetallesVersionesPage;
